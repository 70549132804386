import Avatar, { AvatarItem } from '@atlaskit/avatar'
import { LoadingButton } from '@atlaskit/button'
import { useCallback, useEffect, useMemo } from 'react'

import {
  TestExecMatchingFragment,
  useListAutomationJobsQuery,
  useRunAutopilotJobManuallyMutation,
} from '../../../../graphql'

import { ContentContainer, SubheaderContainer } from './styled'

const CREATE_EXEC_ON_ACCEPTED_MATCH_AUTOMATION_ID =
  'a006fab4-fd90-11ec-bc16-017aeb8754b7'

type Props = {
  matching: TestExecMatchingFragment
  onExecCreated: () => void
}

const ConfirmedView = ({ matching, onExecCreated }: Props) => {
  const [runJob, { loading: runAutopilotJobLoading }] =
    useRunAutopilotJobManuallyMutation({
      onCompleted: () => {
        onExecCreated()
      },
    })

  const confirmedAssistantProfile = useMemo(() => {
    if (!matching.confirmedAssistant?.id) return null

    return matching.proposals.find(
      (proposal) => proposal.assistant.id === matching.confirmedAssistant?.id,
    )?.assistant.profile
  }, [matching.confirmedAssistant?.id, matching.proposals])

  // retrieve the "create exec" Auto pilot job for this matching
  const { data, refetch: refetchJobs } = useListAutomationJobsQuery({
    variables: {
      automationIds: [CREATE_EXEC_ON_ACCEPTED_MATCH_AUTOMATION_ID],
      hasParams: {
        matchingId: matching.id,
      },
      first: 1,
    },
    skip: !confirmedAssistantProfile,
  })
  const createExecJobId = useMemo(
    () => data?.list.items[0]?.id,
    [data?.list.items],
  )

  useEffect(() => {
    if (createExecJobId || !confirmedAssistantProfile) return

    // refetch jobs until we find the "create exec on accepted match" job
    const intervalId = setInterval(() => {
      refetchJobs()
    }, 1000)

    return () => {
      clearTimeout(intervalId)
    }
  }, [confirmedAssistantProfile, createExecJobId, refetchJobs])

  const onCreateExec = useCallback(() => {
    if (!createExecJobId) return

    runJob({ variables: { input: { jobId: createExecJobId } } })
  }, [createExecJobId, runJob])

  if (!confirmedAssistantProfile) return null

  return (
    <>
      <SubheaderContainer>
        <h3>{'Confirmed Assistant'}</h3>
      </SubheaderContainer>
      <ContentContainer>
        <AvatarItem
          avatar={<Avatar src={confirmedAssistantProfile.avatarUrl || ''} />}
          primaryText={confirmedAssistantProfile.displayName}
        />
        <div>
          <LoadingButton
            appearance={'primary'}
            isLoading={runAutopilotJobLoading || !createExecJobId}
            onClick={onCreateExec}
          >
            {'Create Executive(s)'}
          </LoadingButton>
        </div>
      </ContentContainer>
    </>
  )
}

export default ConfirmedView
