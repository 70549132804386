import React from 'react'

import {
  HeaderRow,
  HeaderTitleCell,
  HeaderLabelCell,
  HeaderActionsCell,
} from './styled'

type Props = {
  children?: React.ReactNode
}

const HeadersRow = ({ children }: Props) => (
  <HeaderRow>
    <HeaderTitleCell />
    <HeaderLabelCell>{'Account Supervisor'}</HeaderLabelCell>
    <HeaderLabelCell>{'Started'}</HeaderLabelCell>
    <HeaderLabelCell>{'Google 2FA'}</HeaderLabelCell>
    <HeaderLabelCell>{'Slack'}</HeaderLabelCell>
    <HeaderLabelCell>{'Tech setup'}</HeaderLabelCell>
    <HeaderLabelCell>{'Profile'}</HeaderLabelCell>
    <HeaderLabelCell>{'Can access app'}</HeaderLabelCell>
    <HeaderLabelCell>{'All done!'}</HeaderLabelCell>
    <HeaderActionsCell>{children}</HeaderActionsCell>
  </HeaderRow>
)

export default HeadersRow
