import { LoadingButton } from '@atlaskit/button'
import styled from 'styled-components'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Button = styled(LoadingButton)`
  margin-left: auto;
`
