import { MatchingForTransitionStatus } from './Scheduling/types'

export const MEET_YOUR_DOUBLE_URL = `${
  process.env.REACT_APP_GRAPHQL_ENDPOINT?.includes('localhost')
    ? 'http://localhost:8080'
    : 'https://app.withdouble.com'
}/meet-your-double?token=`

export const SCHEDULING_REGULAR_TRANSITION_TABS = [
  {
    label: 'We found you a new Double!',
    // This message template ID is used on the backend for an Autopilot trigger ⚠️
    messageTemplateIds: ['4fe8591a-cb52-48aa-9cfb-fa58836319d2'],
  },
  {
    label: 'Follow-up 1',
    messageTemplateIds: ['201c60ee-7eae-45f2-b1f9-5397f110a377'],
  },
  {
    label: 'Follow-up 2',
    messageTemplateIds: ['c99fb9cc-f7c2-4b55-b280-65260c877f6d'],
  },
  {
    label: 'Follow-up 3',
    messageTemplateIds: ['0cd51e6e-3e66-4d60-baa3-07ac96482616'],
  },
  {
    label: "We're pausing you for now",
    messageTemplateIds: ['235d4346-d184-4b6a-b870-54d9ce72710e'],
  },
]

export const SCHEDULING_HTT_TRANSITION_TABS = [
  {
    label: 'HTT: Meet your Double!',
    messageTemplateIds: ['1d8106b4-5b5e-47c1-99a3-35dcc3805a36'],
  },
  {
    label: 'Intro call guidelines (Clients)',
    messageTemplateIds: ['4e7e050d-42b4-4889-9cc4-8ad7ccecc233'],
  },
  {
    label: "We're pausing you for now",
    messageTemplateIds: ['235d4346-d184-4b6a-b870-54d9ce72710e'],
  },
]

export const STATUS_APPEARANCE_MAP = {
  [MatchingForTransitionStatus.INTRO_CALL_NOT_SCHEDULED]: 'moved',
  [MatchingForTransitionStatus.INTRO_CALL_SCHEDULED]: 'inprogress',
  [MatchingForTransitionStatus.ASSISTANT_NOT_CONFIRMED]: 'moved',
  [MatchingForTransitionStatus.TRANSITION_CALL_NOT_SCHEDULED]: 'moved',
  [MatchingForTransitionStatus.TRANSITION_CALL_SCHEDULED]: 'inprogress',
  [MatchingForTransitionStatus.TRANSITION_CALL_COMPLETED]: 'success',
}
