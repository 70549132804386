import Avatar, { AvatarItem } from '@atlaskit/avatar'
import { LoadingButton } from '@atlaskit/button'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import { useCallback, useMemo } from 'react'

import { MoreDropdownMenu } from '../../../../../components/DropdownMenu'
import {
  MatchingProposalReply,
  TestExecMatchingFragment,
  useConfirmMatchingAssistantMutation,
  useUpsertMatchingProposalMutation,
} from '../../../../../graphql'
import { MEET_YOUR_DOUBLE_URL } from '../../../../operations/Transitions/Panel/CONSTANTS'
import { ContentContainer, SubheaderContainer } from '../styled'

import { ActiveProposalsContainer, ButtonsContainer } from './styled'

const DWIGHT_USER_ID = '5ef4c642db0e9d00b4e3c5a5'
const KONSTANTIN_USER_ID = '5ef4c74cdb0e9d00b4e3c5a8'

type Props = {
  matching: TestExecMatchingFragment
}

const ProposalsView = ({ matching }: Props) => {
  const [upsertProposal, { loading: upsertMatchingProposalLoading }] =
    useUpsertMatchingProposalMutation()
  const [confirmAssistant, { loading: confirmMatchingAssistantLoading }] =
    useConfirmMatchingAssistantMutation()

  const loading =
    upsertMatchingProposalLoading || confirmMatchingAssistantLoading

  const onConfirmAssistant = useCallback(
    (assistantId: string) => {
      confirmAssistant({
        variables: {
          input: {
            matchingId: matching.id,
            assistantId,
          },
        },
      })
    },
    [confirmAssistant, matching.id],
  )

  const onUpsertProposal = useCallback(
    async (assistantId: string) => {
      await upsertProposal({
        variables: {
          input: {
            matchingId: matching.id,
            assistantId,
            reply: MatchingProposalReply.YES,
            reasonForMatch:
              '- Some cool stuff\n- Some REALLY cool stuff\n- And some mind-blowing stuff 🤯',
          },
        },
      })
    },
    [matching.id, upsertProposal],
  )

  // check if matching proposals contain Dwight or Konstantin
  const containsDwight = useMemo(
    () =>
      matching.proposals.some(
        (proposal) => proposal.assistant.id === DWIGHT_USER_ID,
      ),
    [matching.proposals],
  )
  const containsKonstantin = useMemo(
    () =>
      matching.proposals.some(
        (proposal) => proposal.assistant.id === KONSTANTIN_USER_ID,
      ),
    [matching.proposals],
  )

  return (
    <>
      <SubheaderContainer>
        <h3>{'Proposals'}</h3>
        {matching.isHighTouch && (
          <MoreDropdownMenu position={'bottom left'}>
            <DropdownItem
              key={'copy-meet-your-double-link'}
              onClick={() =>
                navigator.clipboard.writeText(
                  `${MEET_YOUR_DOUBLE_URL}${matching.id}`,
                )
              }
              elemBefore={<CopyIcon size={'small'} label={''} />}
              description={`${MEET_YOUR_DOUBLE_URL}${matching.id}`}
            >
              {'Copy Meet Your Double Link'}
            </DropdownItem>
          </MoreDropdownMenu>
        )}
      </SubheaderContainer>
      <ContentContainer>
        {matching.proposals.length ? (
          <ActiveProposalsContainer>
            {matching.proposals.map((proposal) => {
              const { displayName, avatarUrl } = proposal.assistant.profile
              return (
                <div key={proposal.id}>
                  <AvatarItem
                    avatar={<Avatar src={avatarUrl!} />}
                    primaryText={displayName}
                  />
                  <LoadingButton
                    isLoading={loading}
                    onClick={() => {
                      onConfirmAssistant(proposal.assistant.id)
                    }}
                  >
                    {'Confirm Assistant'}
                  </LoadingButton>
                </div>
              )
            })}
          </ActiveProposalsContainer>
        ) : (
          <p>{'No proposals currently'}</p>
        )}
        {!(containsDwight && containsKonstantin) && (
          <ButtonsContainer>
            {!containsDwight && (
              <LoadingButton
                onClick={() => onUpsertProposal(DWIGHT_USER_ID)}
                isLoading={loading}
              >
                {'Add Dwight Proposal'}
              </LoadingButton>
            )}
            {!containsKonstantin && (
              <LoadingButton
                onClick={() => onUpsertProposal(KONSTANTIN_USER_ID)}
                isLoading={loading}
              >
                {'Add Konstantin Proposal'}
              </LoadingButton>
            )}
          </ButtonsContainer>
        )}
      </ContentContainer>
    </>
  )
}

export default ProposalsView
