import React from 'react'
import styled from 'styled-components'

import {
  Checkbox,
  Field,
  SearchTextField,
  Select,
} from '../../../components/form'
import UserSelect from '../../../components/users/Select'
import {
  StripeCustomerPaymentStatus,
  StripeInvoiceStatus,
  UserCategory,
} from '../../../graphql'

import { Filter, Exception, NO_INVOICE } from './utils'

const Outer = styled.div``

type Props = {
  value: Filter
  onPatch: (patch: Partial<Filter>) => void
}

const getSelectOption = <T extends string>(e: T) => ({
  value: e,
  label: e,
})

const EXCEPTION_OPTIONS = Object.values(Exception).map(getSelectOption)
const PAYMENT_STATUS_OPTIONS = Object.values(StripeCustomerPaymentStatus).map(
  getSelectOption,
)
const INVOICE_STATUS_OPTIONS = [
  NO_INVOICE,
  ...Object.values(StripeInvoiceStatus),
].map(getSelectOption)

const FilterEditor = ({ value, onPatch }: Props) => (
  <Outer>
    <Field label={'Filter by executive or assistant name'}>
      <SearchTextField
        placeholder={'James Doe'}
        value={value.search}
        onChangeValue={(search) => onPatch({ search })}
        isInvalid={!!value.search}
      />
    </Field>
    <Field label={'Filter by exception'}>
      <Select<Exception>
        isClearable
        options={EXCEPTION_OPTIONS}
        value={value.exception ? getSelectOption(value.exception) : undefined}
        placeholder={'Select exception'}
        onChangeValue={(exception) => onPatch({ exception })}
      />
    </Field>
    <Field label={'Filter by payment status'}>
      <Select<StripeCustomerPaymentStatus>
        isClearable
        options={PAYMENT_STATUS_OPTIONS}
        value={
          value.paymentStatus ? getSelectOption(value.paymentStatus) : undefined
        }
        placeholder={'Select payment status'}
        onChangeValue={(paymentStatus) => onPatch({ paymentStatus })}
      />
    </Field>
    <Field label={'Filter by invoice status'}>
      <Select<StripeInvoiceStatus>
        isClearable
        options={INVOICE_STATUS_OPTIONS}
        value={
          value.invoiceStatus ? getSelectOption(value.invoiceStatus) : undefined
        }
        placeholder={'Select invoice status'}
        onChangeValue={(invoiceStatus) => onPatch({ invoiceStatus })}
      />
    </Field>
    <Field label={'Filter by Account Supervisor'}>
      <UserSelect
        defaultOptions
        isClearable
        placeholder={'Select Account Supervisor'}
        menuPlacement={'top'}
        categories={[UserCategory.TEAM]}
        // 💩 This is a hack, admin roles are set in db by the users.
        // They shouldn't be hard-coded and used in the code
        adminRoles={['EA_TEAM_LEAD']}
        onChange={(user) => onPatch({ successManagerId: user?.id })}
        value={
          value.successManagerId ? { id: value.successManagerId } : undefined
        }
      />
    </Field>
    <Field label={'Exclude CHURNED (GRACE) customers with 0 hours'}>
      <Checkbox
        isChecked={value.excludeChurnedNoHours}
        onChangeValue={(excludeChurnedNoHours) =>
          onPatch({ excludeChurnedNoHours })
        }
      />
    </Field>
  </Outer>
)

export default FilterEditor
