import React, { useCallback } from 'react'

import AdminRolesSelect from '../../../../components/AdminRolesSelect'
import GuardedOperationsSelect from '../../../../components/GuardedOperationsSelect'
import Section from '../../../../components/Section'
import { Field } from '../../../../components/form'
import {
  onlyIfAdminable,
  useGetAdminableConfigQuery,
  useUpdateAdminableRolesMutation,
} from '../../../../graphql'
import useIsAllowedOperation from '../../../../hooks/useIsAllowedOperation'

type Props = {
  userId: string
}

const Acl = ({ userId }: Props) => {
  const isAllowedToUpdateRoles = useIsAllowedOperation()(
    'Mutation.updateAdminableRoles',
  )

  const { data, loading: loadingData } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const adminable = onlyIfAdminable(data?.user)

  const [update, { loading: loadingUpdate }] = useUpdateAdminableRolesMutation()

  const updateAdminRoles = useCallback(
    (adminRoleIds) =>
      update({
        variables: {
          input: {
            userId,
            adminRoleIds,
          },
        },
      }),
    [update, userId],
  )

  const loading = loadingData || loadingUpdate

  if (!adminable) {
    return null
  }

  return (
    <Section title={'Cockpit Permissions'}>
      <Field label={'Roles'} isRequired>
        <AdminRolesSelect
          isLoading={loading}
          values={adminable.adminRoles.map(({ id }) => id)}
          onChangeValues={updateAdminRoles}
          isDisabled={!isAllowedToUpdateRoles}
        />
      </Field>

      <Field label={'Allowed operations'}>
        <GuardedOperationsSelect
          operationIds={[]}
          inheritedRoleIds={adminable.adminRoles.map(({ id }) => id)}
        />
      </Field>
    </Section>
  )
}

export default Acl
