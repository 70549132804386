import Button from '@atlaskit/button'
import CodeIcon from '@atlaskit/icon/glyph/code'
import InfoIcon from '@atlaskit/icon/glyph/info'
import Tooltip from '@atlaskit/tooltip'
import { useCallback } from 'react'

import { useClearDataloadersMutation } from '../../../graphql'
import auth from '../../../lib/auth'
import { Page } from '../../types'

import { Outer, Row } from './styled'

const DebuggingTools: Page = () => {
  const [clearDataloaders] = useClearDataloadersMutation()

  const onAuthorizationClick = useCallback(() => {
    const authorization = auth.token ? `Bearer ${auth.token}` : ''
    navigator.clipboard.writeText(authorization)
  }, [])

  const onClearDataloadersClick = useCallback(async () => {
    await clearDataloaders()

    window.location.reload()
  }, [clearDataloaders])

  return (
    <Outer>
      <Row>
        <Button appearance={'primary'} onClick={onAuthorizationClick}>
          {'Copy Authorization Header'}
        </Button>
        <Tooltip
          content={
            'Copy the authorization header in order to replicate authorization in Apollo Sandbox'
          }
          position={'right'}
        >
          <InfoIcon label={'More info'} size={'small'} />
        </Tooltip>
      </Row>
      <Row>
        <Button appearance={'primary'} onClick={onClearDataloadersClick}>
          {'Clear Dataloaders'}
        </Button>
        <Tooltip
          content={
            'Clear the cache (dataloaders) in order to retrieve fresh data from the database (this will NOT clear cache for the workers)'
          }
          position={'right'}
        >
          <InfoIcon label={'More info'} size={'small'} />
        </Tooltip>
      </Row>
    </Outer>
  )
}

DebuggingTools.path = '/debugging-tools'
DebuggingTools.navTitle = 'Debugging Tools'
DebuggingTools.Icon = CodeIcon
DebuggingTools.requiredOperationIds = ['Query.usersList']

export default DebuggingTools
