import Button from '@atlaskit/button'
import UserIcon from '@atlaskit/icon/glyph/person'
import MatchingIcon from '@atlaskit/icon/glyph/vid-raised-hand'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { AirtableLinkButton } from '../../../../components/AirtableButton'
import { MoreDropdownMenu } from '../../../../components/DropdownMenu'
import { TestExecFragment } from '../../../../graphql'

import ExecNameTitle from './ExecNameTitle'
import { HeaderContainer } from './styled'

type Props = {
  testExec: TestExecFragment
  actionItems?: JSX.Element
}

const Header = ({ testExec, actionItems }: Props) => {
  const { lead, matching, user } = testExec
  const history = useHistory()

  const navigateToMatching = useCallback(() => {
    if (!matching?.id) return

    history.push(`/matchings/${matching.id}`)
  }, [history, matching?.id])

  const navigateToUser = useCallback(() => {
    if (!user?.id) return

    history.push(`/users/${user.id}`)
  }, [history, user?.id])

  return (
    <HeaderContainer>
      <ExecNameTitle testExec={testExec} />
      {user?.id && (
        <Button
          appearance={'subtle'}
          onClick={navigateToUser}
          iconBefore={<UserIcon label={''} />}
        >
          {'User Profile'}
        </Button>
      )}
      {matching?.id && (
        <Button
          appearance={'subtle'}
          onClick={navigateToMatching}
          iconBefore={<MatchingIcon label={''} />}
        >
          {'Matching Profile'}
        </Button>
      )}
      {lead?.id && (
        <AirtableLinkButton
          tableId={'tblOzxDMT8Fguk2iq'}
          viewId={'viw2eRewKXaLXvUu9'}
          recordId={lead.id}
        >
          {'Lead Card'}
        </AirtableLinkButton>
      )}
      {actionItems && <MoreDropdownMenu>{actionItems}</MoreDropdownMenu>}
    </HeaderContainer>
  )
}

export default Header
