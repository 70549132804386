import { useApolloClient } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import React, { useCallback } from 'react'

import {
  BasicTeamFragment,
  ListTeamsQuery,
  ListTeamsQueryVariables,
  ListTeamsDocument,
} from '../../../graphql'
import AsyncSelectWithDebounce, {
  SelectProps,
} from '../../AsyncSelectWithDebounce'

import { MultiValueLabel, Option, SingleValue } from './SelectComponents'

const PAGE_SIZE = 20

export type OptionType = Pick<BasicTeamFragment, 'id'>

type Props = SelectProps<OptionType> & {
  pageSize?: number
  sortByName?: boolean
  lookup?: string
  excludeNonDefaultOptions?: boolean
  isDisabled?: boolean
  isArchived?: boolean | null
  keepOptionsWhenEmpty?: boolean
  onChange: (value: OptionType | null) => void
}

const TeamSelect = ({
  defaultOptions,
  pageSize,
  sortByName,
  lookup,
  onChange,
  value,
  excludeNonDefaultOptions,
  isDisabled,
  isArchived,
  keepOptionsWhenEmpty,
  ...props
}: Props) => {
  const client = useApolloClient()

  // explicitly set isMulti to false
  props.isMulti = false

  const previousNonEmptyLoadOptionsRef = React.useRef<OptionType[]>([])
  const loadOptions = useCallback(
    async (search: string): Promise<OptionType[]> => {
      if (!search && !defaultOptions) {
        return previousNonEmptyLoadOptionsRef.current || []
      }

      const { data, error } = await client.query<
        ListTeamsQuery,
        ListTeamsQueryVariables
      >({
        query: ListTeamsDocument,
        variables: {
          q: search,
          isArchived,
          first: pageSize || PAGE_SIZE,
        },
      })

      if (error) {
        return []
      }

      const items = sortByName
        ? sortBy(data.list.items, 'name')
        : data.list.items

      // Clear value if not in default options
      if (defaultOptions && excludeNonDefaultOptions && value && !search) {
        const isInDefaultOptions = items.some(({ id }) => id === value.id)
        if (!isInDefaultOptions) {
          console.info('CLEAR')
          onChange(null)
        }
      }

      if (lookup && onChange && !value) {
        const matchingItems = items.filter((item) =>
          item.name?.toLowerCase().includes(lookup.toLowerCase()),
        )
        if (matchingItems.length === 1) {
          onChange(matchingItems[0])
        }
      }

      if (search) {
        previousNonEmptyLoadOptionsRef.current = items
      }

      return items
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, sortByName, excludeNonDefaultOptions, defaultOptions, value],
  )

  return (
    <AsyncSelectWithDebounce<OptionType>
      loadOptions={loadOptions}
      getOptionValue={(team) => team.id}
      components={{
        Option,
        // @ts-ignore
        SingleValue,
        MultiValueLabel,
      }}
      noOptionsMessage={() => 'Search for a team'}
      defaultOptions={
        (keepOptionsWhenEmpty
          ? previousNonEmptyLoadOptionsRef.current || defaultOptions
          : defaultOptions) || []
      }
      onChange={onChange}
      isDisabled={isDisabled}
      value={value}
      {...props}
    />
  )
}

export default TeamSelect
