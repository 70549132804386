import {
  namedOperations,
  BasicTeamFragment,
  BasicTeamFragmentDoc,
  useGetTeamQuery,
} from '.'
import { useApolloClient } from '@apollo/client'
import { useMemo } from 'react'

const useBasicTeam = (
  teamId: string | null | undefined,
): BasicTeamFragment | null => {
  const client = useApolloClient()

  const cached = useMemo(
    () =>
      teamId &&
      client.readFragment<BasicTeamFragment>({
        id: `Team:${teamId}`,
        fragmentName: namedOperations.Fragment.BasicTeam,
        fragment: BasicTeamFragmentDoc,
      }),
    [client, teamId],
  )

  const { data } = useGetTeamQuery({
    variables: { id: teamId! },
    skip: Boolean(!teamId || cached),
  })

  return cached || data?.team || null
}

export default useBasicTeam
