export const getFriendlyTeamName = (teamName: string | null | undefined) => {
  if (!teamName) {
    return 'Unknown Team'
  }

  if (teamName?.toLocaleLowerCase().includes('team')) {
    return teamName
  }

  return `${teamName} Team`
}
