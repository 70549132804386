import { useApolloClient } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import React, { useCallback } from 'react'

import {
  ListUsersDocument,
  ListUsersQuery,
  ListUsersQueryVariables,
  BasicUserFragment,
  UserCategory,
} from '../../../graphql'
import AsyncSelectWithDebounce, {
  SelectProps,
} from '../../AsyncSelectWithDebounce'

import { MultiValueLabel, Option, SingleValue } from './SelectComponents'

const PAGE_SIZE = 20

export type OptionType = Pick<BasicUserFragment, 'id'>

interface Props extends SelectProps<OptionType> {
  categories?: UserCategory[]
  adminRoles?: string[]
  userIds?: string[]
  pageSize?: number
  sortByName?: boolean
  lookup?: string
  excludeNonDefaultOptions?: boolean
  isDisabled?: boolean
  isArchived?: boolean | null
  keepOptionsWhenEmpty?: boolean
}

const UserSelect = ({
  categories,
  adminRoles,
  userIds,
  defaultOptions,
  pageSize,
  sortByName,
  lookup,
  onChange,
  isMulti,
  value,
  excludeNonDefaultOptions,
  isDisabled,
  isArchived,
  keepOptionsWhenEmpty,
  ...props
}: Props) => {
  const client = useApolloClient()

  const previousNonEmptyLoadOptionsRef = React.useRef<OptionType[]>([])
  const loadOptions = useCallback(
    async (search: string): Promise<OptionType[]> => {
      if (!search && !defaultOptions) {
        return previousNonEmptyLoadOptionsRef.current || []
      }

      try {
        let {
          data: {
            list: { items },
          },
        } = await client.query<ListUsersQuery, ListUsersQueryVariables>({
          query: ListUsersDocument,
          variables: {
            q: search,
            categories,
            adminRoles,
            isArchived,
            userIds,
            first: pageSize || PAGE_SIZE,
          },
        })

        if (sortByName) {
          items = sortBy(items, 'profile.displayName')
        }

        // Clear value if not in default options
        if (defaultOptions && excludeNonDefaultOptions && value && !search) {
          const isInDefaultOptions = items.some(({ id }) => id === value.id)
          if (!isInDefaultOptions) {
            console.info('CLEAR')
            // @ts-ignore
            onChange(isMulti ? [] : null)
          }
        }

        // @ts-ignore
        if (lookup && onChange && (isMulti ? !value?.length : !value)) {
          const matchingItems = items.filter((item) =>
            item.profile.displayName
              ?.toLowerCase()
              .includes(lookup.toLowerCase()),
          )
          if (matchingItems.length === 1) {
            // @ts-ignore
            onChange(isMulti ? matchingItems : matchingItems[0])
          }
        }

        if (search) {
          previousNonEmptyLoadOptionsRef.current = items
        }

        return items
      } catch (error) {
        return []
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, sortByName, excludeNonDefaultOptions, defaultOptions, value],
  )

  return (
    <AsyncSelectWithDebounce<OptionType>
      loadOptions={loadOptions}
      getOptionValue={(user) => user.id}
      components={{
        Option,
        // @ts-ignore
        SingleValue,
        MultiValueLabel,
      }}
      noOptionsMessage={() => 'Search for a user'}
      defaultOptions={
        (keepOptionsWhenEmpty
          ? previousNonEmptyLoadOptionsRef.current || defaultOptions
          : defaultOptions) || []
      }
      isMulti={isMulti}
      onChange={onChange}
      isDisabled={isDisabled}
      value={value}
      {...props}
    />
  )
}

type SingleUserSelectProps = Omit<Props, 'isMulti'>

export const SingleUserSelect = ({ ...props }: SingleUserSelectProps) => (
  <UserSelect placeholder={'Select user'} {...props} isMulti={false} />
)

type MultiUserSelectProps = Omit<Props, 'isMulti'> & {
  value: OptionType[]
  onChange: (newValue: OptionType[]) => void
  keepInputValueOnSelect?: boolean
}

export const MultiUserSelect = ({
  keepInputValueOnSelect,
  onInputChange: onInputChangeProp,
  onChange: onChangeProp,
  ...props
}: MultiUserSelectProps) => {
  const [inputValueState, setInputValueState] = React.useState('')

  const latestNonEmptyInputValueRef = React.useRef(inputValueState)
  const onInputChange = useCallback(
    (inputValue: string) => {
      onInputChangeProp?.(inputValue)
      setInputValueState(inputValue)

      if (inputValue) {
        latestNonEmptyInputValueRef.current = inputValue
      }
    },
    [onInputChangeProp],
  )

  const onChange = useCallback(
    (newValue: OptionType[]) => {
      onChangeProp(newValue)

      if (keepInputValueOnSelect) {
        setInputValueState(latestNonEmptyInputValueRef.current)
      }
    },
    [keepInputValueOnSelect, onChangeProp],
  )

  return (
    <UserSelect
      keepOptionsWhenEmpty={keepInputValueOnSelect}
      placeholder={'Select users'}
      closeMenuOnSelect={false}
      tabSelectsValue
      inputValue={inputValueState}
      onInputChange={onInputChange}
      // @ts-ignore
      onChange={onChange}
      blurInputOnSelect={false}
      {...props}
      // @ts-ignore
      isMulti
    />
  )
}

export default UserSelect
