import styled from 'styled-components'

export const CreateLeadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
  }

  input {
    width: 40px;
  }
`
