import { TimePicker } from '@atlaskit/datetime-picker'
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'
import { CheckboxSelect, RadioSelect, OptionType } from '@atlaskit/select'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import Section from '../../../../components/Section'
import TimeZoneSelect from '../../../../components/TimeZoneSelect'
import {
  Field,
  FieldsRow,
  DebouncedTextField,
} from '../../../../components/form'
import { LanguagesSelect } from '../../../../components/languages/select'
import {
  onlyIfAdminable,
  useGetAdminableConfigQuery,
  useUpdateAdminableMutation,
  UpdateAdminableInput,
  useGetMeQuery,
} from '../../../../graphql'

type NotFalsy<T> = T extends null ? never : T extends undefined ? never : T

const VisitLink = styled.a`
  margin-left: 8px;
  margin-right: 8px;
`

type Props = {
  userId: string
}

const ScheduleOnboardingConfig = ({ userId }: Props) => {
  const { data: meData } = useGetMeQuery()
  const isMe = userId === meData?.me.id

  const { data, loading: loadingData } = useGetAdminableConfigQuery({
    variables: {
      userId,
    },
  })

  const adminable = onlyIfAdminable(data?.user)
  const config = adminable?.scheduleOnboardingConfig

  const [updateAdminable, { loading: loadingUpdate }] =
    useUpdateAdminableMutation()

  const updateScheduleOnboardingConfigField = useCallback(
    (field: keyof NotFalsy<UpdateAdminableInput['scheduleOnboardingConfig']>) =>
      (
        value: NotFalsy<
          UpdateAdminableInput['scheduleOnboardingConfig']
        >[typeof field],
      ) =>
        isMe &&
        updateAdminable({
          variables: {
            input: {
              scheduleOnboardingConfig: {
                [field]: value,
              },
            },
          },
        }),
    [updateAdminable, isMe],
  )

  if (!adminable) {
    return null
  }

  const loading = loadingData || loadingUpdate
  const readableCalendarOptions = (config?.calendars || []).map(
    ({ id, title }): OptionType => ({ value: id, label: title }),
  )
  const specialistCalendarOptions = (config?.calendars || [])
    .filter(
      ({ id, isWritable }) =>
        isWritable && config?.availabilityCalendarIds?.includes(id),
    )
    .map(({ id, title }): OptionType => ({ value: id, label: title }))
  const externalCalendarOptions = (config?.calendars || [])
    .filter(
      ({ isWritable }) => isWritable, // && !config?.availabilityCalendarIds?.includes(id),
    )
    .map(({ id, title }): OptionType => ({ value: id, label: title }))

  return (
    <>
      <Section title={'Schedule onboarding'}>
        <Field
          label={'Working Hours'}
          helperMessage={
            adminable.city?.timeZone
              ? `In the time-zone for your city in Cockpit: ${adminable.city?.address?.formatted}`
              : '🚨 Your city is missing in Cockpit (to know your time-zone)'
          }
        >
          <FieldsRow>
            <TimePicker
              placeholder={'From'}
              value={
                config?.workingStartHour
                  ? `${config.workingStartHour}:00`
                  : undefined
              }
              isInvalid={!config?.workingStartHour}
              times={['7:00', '8:00', '9:00', '10:00']}
              timeIsEditable={false}
              selectProps={{
                isClearable: false,
                isLoading: loading,
                isSearchable: false,
                isDisabled: !isMe,
              }}
              onChange={(isoTime) =>
                updateScheduleOnboardingConfigField('workingStartHour')(
                  parseInt(isoTime),
                )
              }
            />
            <TimePicker
              placeholder={'To'}
              value={
                config?.workingEndHour
                  ? `${config.workingEndHour}:00`
                  : undefined
              }
              isInvalid={!config?.workingEndHour}
              times={['16:00', '17:00', '18:00', '19:00', '20:00']}
              timeIsEditable={false}
              selectProps={{
                isClearable: false,
                isLoading: loading,
                isSearchable: false,
                isDisabled: !isMe,
              }}
              onChange={(isoTime) =>
                updateScheduleOnboardingConfigField('workingEndHour')(
                  parseInt(isoTime),
                )
              }
            />
          </FieldsRow>
        </Field>

        <Field
          label={'Calendars used for availability'}
          helperMessage={
            '"Busy" events on these calendars will forbid scheduling during these times.'
          }
        >
          <CheckboxSelect
            isDisabled={!isMe}
            isLoading={loading}
            options={readableCalendarOptions}
            value={readableCalendarOptions.filter(({ value }) =>
              config?.availabilityCalendarIds?.includes(String(value)),
            )}
            validationState={
              config?.availabilityCalendarIds?.length ? 'default' : 'error'
            }
            onChange={(options) =>
              updateScheduleOnboardingConfigField('availabilityCalendarIds')(
                (options || []).map(({ value }) => String(value)),
              )
            }
          />
        </Field>

        <Field label={'Calendar to book SPECIALIST ONLY event'}>
          <RadioSelect
            isDisabled={!isMe}
            isLoading={loading}
            options={specialistCalendarOptions}
            value={specialistCalendarOptions.find(
              ({ value }) => value === config?.specialistEventCalendarId,
            )}
            validationState={
              config?.specialistEventCalendarId ? 'default' : 'error'
            }
            noOptionsMessage={() =>
              'Please select your availability calendars first'
            }
            onChange={(option) =>
              updateScheduleOnboardingConfigField('specialistEventCalendarId')(
                String(option?.value),
              )
            }
          />
        </Field>

        <Field
          label={'Calendar to book EXECUTIVE + ASSISTANT event'}
          helperMessage={
            config?.availabilityCalendarIds.includes(
              config?.externalEventCalendarId || '',
            )
              ? '⚠️ This calendar is also used for availability'
              : undefined
          }
        >
          <RadioSelect
            isDisabled={!isMe}
            isLoading={loading}
            options={externalCalendarOptions}
            value={externalCalendarOptions.find(
              ({ value }) => value === config?.externalEventCalendarId,
            )}
            validationState={
              config?.externalEventCalendarId ? 'default' : 'error'
            }
            noOptionsMessage={() =>
              'Please select your availability calendars first'
            }
            onChange={(option) =>
              updateScheduleOnboardingConfigField('externalEventCalendarId')(
                String(option?.value),
              )
            }
          />
        </Field>

        <Field
          label={'Covered time-zones'}
          helperMessage={'In order of preference/most comfortable with first'}
        >
          <TimeZoneSelect
            isMulti
            value={config?.coveredTimeZones || []}
            // @ts-ignore
            onChangeValue={updateScheduleOnboardingConfigField(
              'coveredTimeZones',
            )}
          />
        </Field>

        <Field
          label={'Languages'}
          helperMessage={'In order of preference/most comfortable with first'}
        >
          <LanguagesSelect
            values={adminable.languages}
            onChangeValues={updateScheduleOnboardingConfigField('languages')}
          />
        </Field>
      </Section>

      <Section title={'30-day checkin'}>
        <Field label={'Calendly url given to executives to book a check-in'}>
          <DebouncedTextField
            type={'url'}
            debounceMs={500}
            isDisabled={!isMe}
            placeholder={'https://calendly.com/...'}
            value={adminable.thirtyDayCheckInCallUrl || undefined}
            onChangeValue={(value) =>
              updateAdminable({
                variables: {
                  input: {
                    thirtyDayCheckInCallUrl: value || null,
                  },
                },
              })
            }
            elemAfterInput={
              !!adminable.thirtyDayCheckInCallUrl && (
                <VisitLink
                  href={adminable.thirtyDayCheckInCallUrl}
                  target={'calendly'}
                >
                  <ShortcutIcon size={'small'} label={'Visit Calendly'} />
                </VisitLink>
              )
            }
          />
        </Field>
      </Section>
    </>
  )
}

export default ScheduleOnboardingConfig
