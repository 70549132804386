import React from 'react'

import SlackButton, {
  SlackLinkButton,
  COMMUNITY_TEAM_ID,
} from '../../../../components/SlackButton'
import {
  ToggleOuter,
  ToggleStateless as Toggle,
} from '../../../../components/Toggle'
import VerticalTabs from '../../../../components/VerticalTabs'
import { Field, FieldsRow } from '../../../../components/form'
import UserSelect from '../../../../components/users/Select'
import {
  useCreateTransitionSlackChannelMutation,
  UserCategory,
  useUpdateTransitionInformingMutation,
  Matching_ForTransitionFullFragment,
} from '../../../../graphql'
import { Bundled } from '../utils'

import InformingCategorySelect from './components/InformingCategorySelect'
import InformingStatusSelect from './components/InformingStatusSelect'
import PreviewAndSendEmailButton from './components/PreviewAndSendEmailButton'
import { LimitedWidth, FieldSpacer, InfoNotice, InfoNoticeText } from './styled'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

const Informing = ({ matching }: Props) => {
  const [createSlackChannel, createSlackChannelMutation] =
    useCreateTransitionSlackChannelMutation({
      variables: {
        input: {
          matchingId: matching.id,
        },
      },
    })

  const [updateTransitionInforming, { loading }] =
    useUpdateTransitionInformingMutation()

  return (
    <>
      <Field label={'Owner'}>
        <LimitedWidth>
          <UserSelect
            categories={[UserCategory.TEAM]}
            adminRoles={['EA_TEAM_LEAD']}
            value={matching.transition!.csm}
            defaultOptions
            isSearchable={true}
            isLoading={loading}
            isDisabled={loading}
            onChange={(user) =>
              user &&
              updateTransitionInforming({
                variables: {
                  input: {
                    matchingId: matching.id,
                    successSpecialistId: user.id,
                  },
                },
              })
            }
          />
        </LimitedWidth>
      </Field>

      <Field label={'Current status'}>
        <FieldsRow>
          <LimitedWidth>
            <InformingStatusSelect
              value={matching.transition?.informingStatus}
              onChangeValue={(informingStatus) =>
                informingStatus &&
                updateTransitionInforming({
                  variables: {
                    input: {
                      matchingId: matching.id,
                      informingStatus,
                    },
                  },
                })
              }
              isDisabled={loading}
              isLoading={loading}
            />
          </LimitedWidth>
          <InfoNotice type={'info'} title={'What is this doing?'} noTopUnset>
            <InfoNoticeText>
              {'All new transitions will start out with the "Hold" status.'}
              <br />
              <br />
              {
                'For exec initiated transitions: The CS owner should update the current status first.'
              }
              <br />
              <br />
              {
                'For Double initiated transitions: the Account Supervisor should update the current status first.'
              }
              <br />
              <br />
              {
                'A new CS next step will ping the CS owner in Slack. A new Account Supervisor next step will ping the Account Supervisor in Slack.'
              }
              <br />
              <br />
              {'🤖 When moved to "Exec informed" '}
              <a
                href={`/message-templates/499085a9-ee3d-4abb-bc16-bfd28bfca5ce`}
                target={'_blank'}
              >
                {'a message'}
              </a>
              {
                ' is automatically sent by Double Bot in the Slack channel (see below).'
              }
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>

      <Field label={'Category'}>
        <FieldsRow>
          <LimitedWidth>
            <InformingCategorySelect
              value={matching.transition?.informingCategory}
              onChangeValue={(informingCategory) =>
                informingCategory &&
                updateTransitionInforming({
                  variables: {
                    input: {
                      matchingId: matching.id,
                      informingCategory,
                    },
                  },
                })
              }
              isDisabled={loading}
              isLoading={loading}
            />
          </LimitedWidth>
        </FieldsRow>
      </Field>

      <FieldSpacer />

      <Field
        label={
          'Email templates to notify executives (for Double initiated transitions)'
        }
      >
        <VerticalTabs
          tabs={[
            {
              label: "Your Double's availability changed",
              messageTemplateIds: [
                '4ccf7f75-8bd7-4145-8d4d-05046d916fd8',
                '95ec7e30-2953-41f8-8f1d-e2e2df0b8975',
                'b23968d2-232f-45fc-9153-907d3ef398fd',
              ],
            },
            {
              label: 'Send reminder 1 (1 day later)',
              messageTemplateIds: ['3b79d7d8-2a28-4bb5-b6aa-0cb4caaa4425'],
            },
            {
              label: 'Send reminder 2 (1 day later)',
              messageTemplateIds: ['3859f90e-6091-4d4d-b0e6-b2e7cbffe6da'],
            },
            {
              label: 'Send reminder 3 (2 days later)',
              messageTemplateIds: ['a9373891-7466-4c39-99fd-9b555e0c0bf5'],
            },
            {
              label: "We're pausing you for now (1 day later)",
              messageTemplateIds: ['1ea4afc5-2d6c-4856-9389-772defdd0758'],
            },
          ].map(({ label, messageTemplateIds }) => ({
            label,
            isChecked: matching.transition?.sentMessages?.some((sent) =>
              messageTemplateIds.includes(sent.messageTemplate.id),
            ),
            content: (
              <PreviewAndSendEmailButton
                matching={matching}
                messageTemplateIds={messageTemplateIds}
                composerProps={{
                  email: {
                    bcc: [
                      ...new Set([
                        matching.transition?.fromAssistant?.dsm?.profile
                          .email || undefined,
                        matching.confirmedAssistant?.dsm?.profile.email ||
                          undefined,
                        matching.transition?.csm?.profile.email || undefined,
                      ]),
                    ]
                      .filter(Boolean)
                      .map((email) => ({ email })),
                    vars: {
                      SENDER_GIVEN_NAME:
                        matching.transition?.csm?.profile.givenName,
                    },
                  },
                }}
              />
            ),
          }))}
        />
      </Field>

      <FieldSpacer />

      <Field label={'Slack DM for this transition'}>
        <FieldsRow>
          <div>
            {matching.transition?.slackChannelId ? (
              <SlackLinkButton
                appearance={'default'}
                teamId={COMMUNITY_TEAM_ID}
                channelId={matching.transition?.slackChannelId}
              >
                {'Open Slack DM'}
              </SlackLinkButton>
            ) : (
              <SlackButton
                appearance={'default'}
                onClick={() => createSlackChannel()}
                isLoading={!!createSlackChannelMutation.loading}
              >
                {'Create Slack DM'}
              </SlackButton>
            )}
          </div>
          <InfoNotice
            type={'info'}
            title={'When do I use that?'}
            noTopUnset
            placement={'top'}
          >
            <InfoNoticeText>
              {
                'When all parties are informed, press "Create" to create a Slack DM with the Double, their TL, CS owner and Double Bot. This channel will be used to keep the Double up to date on the status of their transition.'
              }
              <br />
              <br />
              {'🤖 When the channel is created, '}
              <a
                href={`/message-templates/dcd6674b-d513-4580-b3c0-0b37c2b124cd`}
                target={'_blank'}
              >
                {'a welcome message'}
              </a>
              {' is automatically sent by Double Bot.'}
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>

      <Field label={'Automatically send updates to this Slack channel'}>
        <FieldsRow>
          <div>
            <ToggleOuter>
              <Toggle
                isChecked={!matching.transition?.hasDisabledSlackMessages}
                onChange={() => {
                  updateTransitionInforming({
                    variables: {
                      input: {
                        matchingId: matching.id,
                        hasDisabledSlackMessages:
                          !matching.transition?.hasDisabledSlackMessages,
                      },
                    },
                  })
                }}
              />
            </ToggleOuter>
          </div>
          <InfoNotice
            type={'info'}
            title={'Why would I need to change that?'}
            noTopUnset
            placement={'top'}
          >
            <InfoNoticeText>
              {
                'Some updates are sent automatically to the Slack channel above (EVEN IF NOT CREATED YET) when some events happen (executive has been informed, executive has been matched with a new Double, etc).'
              }
              <br />
              {
                'You may disable these updates to avoid informing the leaving Double. Can be useful for special situations.'
              }
            </InfoNoticeText>
          </InfoNotice>
        </FieldsRow>
      </Field>
    </>
  )
}

export default Informing
