import ManagerIcon from '@atlaskit/icon/glyph/people'
// @ts-ignore
import { ContainerHeader, Group, Item } from '@atlaskit/navigation-next'
import React, { useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { LoadingSpinner } from '../../../components/Spinner'
import Avatar from '../../../components/teams/Avatar'
import { UserAvatarsGroup } from '../../../components/users/Avatar'
import { useGetTeamQuery } from '../../../graphql'

import { NavLink, Outer } from './styled'

const Sidebar = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const {
    params: { activeWorkspaceId },
  } = useRouteMatch<{ activeWorkspaceId: string }>(
    '/teams/:teamId/workspaces/:activeWorkspaceId',
  ) || { params: {} }
  const { data, loading } = useGetTeamQuery({
    variables: { id: teamId },
    skip: !teamId,
  })

  // TODO - implement add team member action
  // const [
  //   isAddTeamMemberModalOpen,
  //   openAddTeamMemberModal,
  //   closeAddTeamMemberModal,
  // ] = useSwitch(false)

  const team = data?.team
  const successManager = team?.successManager

  const principalWorkspace = useMemo(
    () =>
      team?.workspaces.find((workspace) =>
        workspace.executives.some((exec) => exec.id === team.principalUser.id),
      ),
    [team?.principalUser.id, team?.workspaces],
  )

  const teamMemberWorkspaces = useMemo(
    () =>
      (team?.workspaces || []).filter(
        (workspace) => workspace.id !== principalWorkspace?.id,
      ),
    [principalWorkspace?.id, team?.workspaces],
  )

  if (!teamId) {
    return null
  }

  return (
    <Outer>
      <NavLink to={`/teams/${teamId}`} $striked={team?.isArchived}>
        <ContainerHeader
          text={team?.name}
          isSelected={!activeWorkspaceId}
          before={() => (team ? <Avatar team={team} /> : null)}
        />
      </NavLink>
      {principalWorkspace && (
        <Group heading={'Team Principal'}>
          <NavLink
            key={principalWorkspace.id}
            to={`/teams/${teamId}/workspaces/${principalWorkspace.id}`}
          >
            <Item
              isSelected={activeWorkspaceId === principalWorkspace.id}
              text={principalWorkspace.executives[0]?.profile.displayName}
              subText={principalWorkspace.assistants[0]?.profile.displayName}
              before={() => (
                <UserAvatarsGroup
                  size={'small'}
                  maxCount={2}
                  users={[
                    ...principalWorkspace.executives,
                    ...principalWorkspace.assistants,
                  ]}
                />
              )}
              spacing={'compact'}
            />
          </NavLink>
        </Group>
      )}
      {!!teamMemberWorkspaces.length && (
        <Group heading={'Team Members'}>
          {teamMemberWorkspaces.map((workspace) => (
            <NavLink
              key={workspace.id}
              to={`/teams/${teamId}/workspaces/${workspace.id}`}
            >
              <Item
                isSelected={activeWorkspaceId === workspace.id}
                text={workspace.executives[0]?.profile.displayName}
                subText={workspace.assistants[0]?.profile.displayName}
                before={() => (
                  <UserAvatarsGroup
                    size={'small'}
                    maxCount={2}
                    users={[...workspace.executives, ...workspace.assistants]}
                  />
                )}
                spacing={'compact'}
              />
            </NavLink>
          ))}
          {/* <Item
            text={'Add team member'}
            before={() => (
              <AddIcon
                label={''}
                primaryColor={colors.N50}
                secondaryColor={colors.N20}
              />
            )}
            spacing={'compact'}
            // TODO - implement add team member action
            // onClick={openAddTeamMemberModal}
            onClick={() => console.info('add team member')}
          /> */}
        </Group>
      )}
      <Group heading={'Team Account Supervisor'}>
        <Item
          text={successManager?.profile.displayName}
          before={() =>
            successManager ? (
              <UserAvatarsGroup
                size={'small'}
                maxCount={2}
                users={[successManager]}
              />
            ) : (
              <ManagerIcon label={''} />
            )
          }
          spacing={'compact'}
        />
      </Group>
      <LoadingSpinner show={loading} />
      {/* // TODO - implement add team member action */}
      {/* <AddTeamMemberModal
        executiveId={teamId}
        isOpen={isAddTeamMemberModalOpen}
        onClose={closeAddTeamMemberModal}
      /> */}
    </Outer>
  )
}

export default Sidebar
