import FilterIcon from '@atlaskit/icon/glyph/filter'
import Panel from '@atlaskit/panel'
import { useMemo, useRef } from 'react'

import Badge from '../../../../components/Badge'
import DateFormater from '../../../../components/DateFormater'
import { ErrorEmptyState } from '../../../../components/EmptyState'
import PopupButton from '../../../../components/PopupButton'
import { LoadingSpinner } from '../../../../components/Spinner'
import {
  Outer as TimelineOuter,
  Icon,
  IconOuter,
  Line,
  Step,
  StepHeader,
  StepTitle,
} from '../../../../components/Timeline/styled'
import {
  TimelineEvent,
  TimelineItemsFragment,
  useGetUserTimelineQuery,
} from '../../../../graphql'
import useLocalStorage from '../../../../lib/useLocalStorage'
import Item from '../../../operations/Matchings/List/Item'
import WorkspaceItem from '../../Workspace/WorkspaceItem'

import CSATFeedbackRow from './components/CSATFeedbackRow'
import FilterEditor from './components/FilterEditor'
import WorkspaceUserRow from './components/WorkspaceUserRow'
import { Outer, RowItem, StepContent } from './styled'

type Props = {
  userId: string
}

const EVENTS_EXPANDED_DEFAULT = [
  TimelineEvent.MATCHING_CREATED,
  TimelineEvent.WORKSPACE_USER_ADDED,
  TimelineEvent.MATCHING_PROPOSAL_REPLIED,
]

const TITLES_HIDDEN_BY_DEFAULT = ['declined proposal']

const Timeline = ({ userId }: Props) => {
  const [excludeList, setExcludeList, clearExcludeList] = useLocalStorage<
    TimelineEvent[]
  >({
    key: 'timelineFilterExcludes',
    initialValue: [],
  })

  const latestNonEmptyItems = useRef<TimelineItemsFragment[]>([])

  const { data, loading, error } = useGetUserTimelineQuery({
    variables: {
      userId,
      excludes: excludeList,
    },
    fetchPolicy: 'cache-and-network',
  })

  const sortedItems = useMemo(() => {
    const items = data?.timelineItemsList.items || []
    if (items.length !== 0) {
      latestNonEmptyItems.current = items
    }
    return latestNonEmptyItems.current
      .slice()
      .sort((a, b) => {
        return (
          new Date(b.activityAt).getTime() - new Date(a.activityAt).getTime()
        )
      })
      .filter((item) => {
        return !excludeList.some((exclude) => item.event === exclude)
      })
  }, [data?.timelineItemsList.items, excludeList])

  if (error) return <ErrorEmptyState error={error} />
  const lastItemIndex = (sortedItems?.length || 1) - 1
  const isSortedItemsEmpty = sortedItems.length === 0
  const isFiltersEmpty = excludeList.length === 0

  return (
    <Outer>
      <PopupButton
        content={() => (
          <FilterEditor
            excludeList={excludeList}
            setExcludeList={setExcludeList}
            clearExcludeList={clearExcludeList}
          />
        )}
        buttonProps={{
          appearance: 'primary',
          iconAfter: <FilterIcon label={'Filter'} />,
          children: (
            <RowItem>
              {'Timeline Filters'}
              <Badge
                tint={isFiltersEmpty ? 'green' : 'red'}
                text={excludeList.length}
              />
            </RowItem>
          ),
        }}
      />
      <TimelineOuter>
        {isSortedItemsEmpty ? (
          loading ? (
            <LoadingSpinner />
          ) : (
            <ErrorEmptyState error={new Error('Woops, there is no data')} />
          )
        ) : (
          sortedItems.map((item, index) => (
            <Step key={`event-${index}`}>
              <IconOuter>
                <Icon $filled>{item.emoji}</Icon>
                {index !== lastItemIndex && <Line $startFilled $endFilled />}
              </IconOuter>
              <StepContent>
                <Panel
                  isDefaultExpanded={
                    EVENTS_EXPANDED_DEFAULT.includes(item.event) &&
                    !TITLES_HIDDEN_BY_DEFAULT.includes(item.title.toLowerCase())
                  }
                  header={
                    <StepHeader>
                      <StepTitle>{item.title}</StepTitle>
                      <DateFormater dateTime={item.activityAt} noTime />
                    </StepHeader>
                  }
                >
                  {item.__typename === 'WorkspaceUserTimelineItem' && (
                    <>
                      <WorkspaceUserRow
                        user={item.user}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        isCoverage={item.isCoverage}
                      />
                      <WorkspaceItem
                        key={item.workspace.id}
                        isSelected={false}
                        workspace={item.workspace}
                        userId={userId}
                      />
                    </>
                  )}
                  {item.__typename === 'WorkspaceTimelineItem' && (
                    <WorkspaceItem
                      key={item.workspace.id}
                      isSelected={false}
                      workspace={item.workspace}
                      userId={userId}
                    />
                  )}
                  {item.__typename === 'MatchingTimelineItem' && (
                    <Item matching={item.matching} />
                  )}
                  {item.__typename === 'FeedbackTimelineItem' &&
                    item.feedback.workspace?.id && (
                      <CSATFeedbackRow
                        counterpartUser={item.counterpartUser}
                        feedback={item.feedback}
                        workspaceId={item.feedback.workspace.id}
                      />
                    )}
                </Panel>
              </StepContent>
            </Step>
          ))
        )}
      </TimelineOuter>
    </Outer>
  )
}

export default Timeline
