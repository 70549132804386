import React, { ComponentProps } from 'react'

import ErrorBanner from '../../../components/ErrorBanner'
import { LoadingSpinner } from '../../../components/Spinner'
import History from '../../../components/billing/History'
import PricingPackage from '../../../components/billing/PricingPackage'
import StripeCustomer from '../../../components/billing/StripeCustomer'
import {
  useCreateStripeCustomerMutation,
  useGetTeamBillingInfoQuery,
  useSelectEntityPricingMutation,
} from '../../../graphql'
import useSwitch from '../../../lib/useSwitch'
import { PricingDrawer } from '../../finances/Pricings/Pricing'

interface Props extends ComponentProps<'div'> {
  teamId: string
}

const Billing = ({ teamId }: Props) => {
  const [isSelectingPricing, startSelectingPricing, stopSelectingPricing] =
    useSwitch(false)

  const {
    data,
    loading: getTeamBillingLoading,
    error: getTeamBillingError,
  } = useGetTeamBillingInfoQuery({
    variables: { id: teamId },
  })

  const [
    selectEntityPricing,
    { loading: entityPricingLoading, error: entityPricingError },
  ] = useSelectEntityPricingMutation({
    onCompleted: stopSelectingPricing,
  })

  const [
    createStripeCustomer,
    { loading: createStripeCustomerLoading, error: createStripeCustomerError },
  ] = useCreateStripeCustomerMutation({
    variables: { input: { entityId: teamId } },
  })

  const team = data?.team

  const stripeCustomer = team?.stripeCustomer

  const currentTeamPricing = team?.currentPricing
  const pricingSelections = team?.pricingSelections || []

  const error =
    getTeamBillingError || entityPricingError || createStripeCustomerError
  const loading =
    getTeamBillingLoading || entityPricingLoading || createStripeCustomerLoading

  return (
    <>
      <ErrorBanner error={error} />

      <StripeCustomer
        stripeCustomer={stripeCustomer}
        loading={loading}
        createStripeCustomer={createStripeCustomer}
      />

      <PricingPackage
        entity={team}
        currentEntityPricing={currentTeamPricing}
        isSelectingPricing={isSelectingPricing}
        startSelectingPricing={startSelectingPricing}
        stopSelectingPricing={stopSelectingPricing}
        selectEntityPricing={selectEntityPricing}
      />

      <History
        pricingSelections={pricingSelections}
        pricingSelectionsLoading={loading}
      />

      <LoadingSpinner show={loading} />
      <PricingDrawer />
    </>
  )
}

export default Billing
