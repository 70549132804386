import { GetInvoicingHistoryListQueryResult } from '../../graphql'
import { notFalsy } from '../../lib/utils'
import Section from '../Section'
import Tabs from '../Tabs'

import InvoicingHistory from './InvoicingHistory'
import PricingPackageHistory, {
  Props as PricingPackageHistoryProps,
} from './PricingPackageHistory'

type Props = PricingPackageHistoryProps & {
  userId?: string
  invoicingHistory?: GetInvoicingHistoryListQueryResult['data']
  invoicingHistoryLoading?: boolean
  invoicingHistoryError?: Error
}

const History = ({
  userId,
  pricingSelections,
  pricingSelectionsLoading,
  invoicingHistory,
  invoicingHistoryLoading,
  invoicingHistoryError,
}: Props) => {
  const tabs = [
    !!userId && {
      label: 'Invoicing',
      content: (
        <InvoicingHistory
          userId={userId}
          invoicingHistoryData={invoicingHistory}
          loading={invoicingHistoryLoading}
          error={invoicingHistoryError}
        />
      ),
    },
    {
      label: 'Package',
      content: (
        <PricingPackageHistory
          pricingSelections={pricingSelections}
          pricingSelectionsLoading={pricingSelectionsLoading}
        />
      ),
    },
  ].filter(notFalsy)

  if (pricingSelections.length === 0) {
    return null
  }

  return (
    <Section title={'History'}>
      <Tabs leftTabs={tabs} />
    </Section>
  )
}

export default History
