import { useListPricingsQuery } from '../../graphql'

export enum PLAN_CHANGE_TYPE {
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  NO_CHANGE = 'NO_CHANGE',
}

const useGetPlanChangeType = (
  currentPlanId?: string,
  newPlanId?: string,
): PLAN_CHANGE_TYPE => {
  const { data: pricingsData } = useListPricingsQuery()

  const pricings = pricingsData?.list.items || []

  if (!currentPlanId || !newPlanId || currentPlanId === newPlanId)
    return PLAN_CHANGE_TYPE.NO_CHANGE

  const currentPlan = pricings.find(({ id }) => id === currentPlanId)
  const newPlan = pricings.find(({ id }) => id === newPlanId)

  if (!currentPlan || !newPlan) return PLAN_CHANGE_TYPE.NO_CHANGE

  if (currentPlan.modelConfig.basePrice > newPlan.modelConfig.basePrice)
    return PLAN_CHANGE_TYPE.DOWNGRADE
  if (currentPlan.modelConfig.basePrice < newPlan.modelConfig.basePrice)
    return PLAN_CHANGE_TYPE.UPGRADE

  return PLAN_CHANGE_TYPE.NO_CHANGE
}

export default useGetPlanChangeType
