// import DynamicTable from '@atlaskit/dynamic-table'
import TableTree, {
  Cell,
  Header as _Header,
  Headers,
  Row,
  Rows,
} from '@atlaskit/table-tree'
import { colors, typography } from '@atlaskit/theme'
import sortBy from 'lodash/sortBy'
import React from 'react'
import styled, { css } from 'styled-components'

import { Matching_ForTransitionListItemFragment } from '../../../../../graphql'
import useActiveMatching from '../../useActiveMatching'
import { Bundled } from '../../utils'

import { TransitioningAssistant, EnteringAssistant } from './cells/Assistant'
import {
  TransitioningAssistantManager,
  EnteringAssistantManager,
} from './cells/AssistantManager'
import Changeover from './cells/Changeover'
import Deadline from './cells/Deadline'
import Executive from './cells/Executive'
import Handover from './cells/Handover'
import Informing from './cells/Informing'
import Matching from './cells/Matching'
import RequestDate from './cells/RequestDate'
import RequestedBy from './cells/RequestedBy'
import Scheduling from './cells/Scheduling'
import SuccessSpecialist from './cells/SuccessSpecialist'
import { useSorting } from './useSorting'

const Outer = styled.div`
  --indent: 5px;

  [role='columnheader'] {
    padding-right: 5px;

    &:first-child {
      padding-left: 20px;
    }
  }

  [role='gridcell'] {
    display: flex;
    align-items: center;

    &:first-child > span > span:first-child {
      top: -3px;
    }
  }

  [role='rowgroup'] {
    [role='row'] {
      &:hover {
        background: ${colors.N10};
      }

      &[aria-expanded='true'] {
        border-bottom-style: dashed;
      }
    }

    /* Children of row */
    [role='rowgroup'] [role='row']:not(:last-child) {
      border: none;
    }
  }
`

const Caption = styled.h2`
  ${typography.h600()}
  margin-top: 48px;
`

const Header = styled(_Header)<{ $isSortable?: boolean; $isSorted?: boolean }>`
  ${({ $isSortable }) =>
    $isSortable &&
    css`
      cursor: pointer;
      &:after {
        content: '▾';
        margin-left: 4px;
        color: ${colors.N80};
      }
      &:hover,
      &:hover:after {
        color: ${colors.textHover};
      }
    `}

  ${({ $isSorted, $isSortable }) =>
    $isSorted &&
    $isSortable &&
    css`
      &,
      &:after {
        color: ${colors.textActive}!important;
      }
    `}
`

type Props = {
  caption?: string
  matchings: Bundled<Matching_ForTransitionListItemFragment>[]
  sort: boolean
}

const Table = ({ matchings, caption, sort }: Props) => {
  const { matching: activeMatching } = useActiveMatching()
  const [sorting, setSorting] = useSorting()

  const hideStages = !!activeMatching

  return (
    <Outer>
      {!!caption && <Caption>{caption}</Caption>}
      <TableTree>
        <Headers>
          <Header
            width={hideStages ? '10%' : '6%'}
            $isSortable={sort}
            $isSorted={sorting?.key === 'transition.requestedOn'}
            onClick={() =>
              setSorting({
                key: 'transition.requestedOn',
              })
            }
          >
            {'Requested'}
          </Header>
          <Header width={hideStages ? '6%' : '4%'}>{'By'}</Header>
          <Header
            width={hideStages ? '7%' : '4%'}
            $isSortable={sort}
            $isSorted={sorting?.key === 'transition.deadlineOn'}
            onClick={() =>
              setSorting({
                key: 'transition.deadlineOn',
              })
            }
          >
            {'Deadline'}
          </Header>
          <Header width={hideStages ? '20%' : '15%'}>{'Executive'}</Header>
          <Header
            width={hideStages ? '20%' : '15%'}
            $isSortable={sort}
            $isSorted={
              sorting?.key === 'transition.fromAssistant.profile.displayName'
            }
            onClick={() =>
              setSorting({
                key: 'transition.fromAssistant.profile.displayName',
              })
            }
          >
            {'Double 💔'}
          </Header>
          <Header
            width={hideStages ? '5%' : '4%'}
            $isSortable={sort}
            $isSorted={
              sorting?.key ===
              'transition.fromAssistant.manager.profile.displayName'
            }
            onClick={() =>
              setSorting({
                key: 'transition.fromAssistant.manager.profile.displayName',
              })
            }
          >
            {'AS 💔'}
          </Header>
          <Header width={hideStages ? '20%' : '15%'}>{'Double 🆕'}</Header>
          <Header width={hideStages ? '5%' : '4%'}>{'AS 🆕'}</Header>
          <Header
            width={hideStages ? '5%' : '3%'}
            $isSortable={sort}
            $isSorted={sorting?.key === 'transition.csm.profile.displayName'}
            onClick={() =>
              setSorting({
                key: 'transition.csm.profile.displayName',
              })
            }
          >
            {'CS'}
          </Header>
          {hideStages
            ? []
            : [
                <Header key={'inf'} width={'6%'}>
                  {'Informing'}
                </Header>,
                <Header key={'mat'} width={'6%'}>
                  {'Matching'}
                </Header>,
                <Header key={'sch'} width={'6%'}>
                  {'Scheduling'}
                </Header>,
                <Header key={'han'} width={'6%'}>
                  {'Handover'}
                </Header>,
                <Header key={'cha'} width={'6%'}>
                  {'Changeover'}
                </Header>,
              ]}
        </Headers>
        <Rows
          items={
            sort
              ? sortBy(
                  matchings,
                  ({ isHighTouch }) => (isHighTouch ? 0 : 1),
                  ({ transition }) =>
                    transition?.highTouchReasons?.length ? 0 : 1,
                  sorting.key,
                  'transition.deadlineOn',
                  'transition.fromAssistant.profile.displayName',
                  'requestedOn',
                )
              : matchings
          }
          render={(
            matching: Bundled<Matching_ForTransitionListItemFragment>,
          ) => (
            <Row
              itemId={matching.id}
              hasChildren={Boolean(matching.bundle?.length)}
              items={matching.bundle || []}
            >
              <Cell>
                <RequestDate matching={matching} />
              </Cell>
              <Cell>
                <RequestedBy matching={matching} />
              </Cell>
              <Cell>
                <Deadline matching={matching} />
              </Cell>
              <Cell>
                <Executive matching={matching} />
              </Cell>
              <Cell>
                <TransitioningAssistant matching={matching} />
              </Cell>
              <Cell>
                <TransitioningAssistantManager matching={matching} />
              </Cell>
              <Cell>
                <EnteringAssistant matching={matching} />
              </Cell>
              <Cell>
                <EnteringAssistantManager matching={matching} />
              </Cell>
              <Cell>
                <SuccessSpecialist matching={matching} />
              </Cell>
              {hideStages
                ? []
                : [
                    <Cell key={'inf'}>
                      <Informing matching={matching} />
                    </Cell>,
                    <Cell key={'mat'}>
                      <Matching matching={matching} />
                    </Cell>,
                    <Cell key={'sch'}>
                      <Scheduling matching={matching} />
                    </Cell>,
                    <Cell key={'han'}>
                      <Handover matching={matching} />
                    </Cell>,
                    <Cell key={'cha'}>
                      <Changeover matching={matching} />
                    </Cell>,
                  ]}
            </Row>
          )}
        />
      </TableTree>
    </Outer>
  )
}

export default Table
