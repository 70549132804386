import React, { ComponentProps, useMemo } from 'react'

import { UserAccountType, UserAccount } from '../../graphql'

import gsuiteIconSrc from './gsuite.png'
import slackIconSrc from './slack.png'
import unknownIconSrc from './unknown.png'

interface Props extends ComponentProps<'img'> {
  account: Pick<UserAccount, 'type' | 'label'>
}

const imgStyle = {
  width: 16,
  height: 16,
}

const AccountIcon = ({ account: { type, label }, style, ...props }: Props) => {
  const src = useMemo(() => {
    if (label.endsWith('@gmail.com')) {
      return gsuiteIconSrc
    }

    switch (type) {
      case UserAccountType.SLACK:
        return slackIconSrc
      case UserAccountType.GOOGLE:
        return gsuiteIconSrc
      case UserAccountType.EMAIL:
        return unknownIconSrc
    }
  }, [type, label])

  return (
    <img alt={label} src={src} style={{ ...imgStyle, ...style }} {...props} />
  )
}

export default AccountIcon
