import { DateTime } from 'luxon'

import { InvoicingReportFragment } from '../../../../graphql'
import { createWritableCSVFileStream } from '../../../../lib/writeFile'
import { formatHours } from '../../Contractors/utils'
import {
  ReportSummary,
  ReportsQuery,
  InvoiceEdit,
  getReportTotals,
  getUsageException,
  getReportSection,
  SECTIONS,
  isTeam,
} from '../utils'

type Line = Partial<{
  section: string
  teamIndividual: string | undefined
  invoicedExecutiveId: string | null | undefined
  invoicedExecutiveName: string | null | undefined
  executiveId: string | null | undefined
  executiveName: string | null | undefined
  assistantId: string | null | undefined
  assistantName: string | null | undefined
  successManagerId: string | null | undefined
  successManagerName: string | null | undefined
  pricingPackage: string | null | undefined
  startDate: string | null | undefined
  exception: string | null | undefined
  hours: string | null | undefined
  calculatedAmount: string | null | undefined
  invoiceAmount: string | null | undefined
  stripeCustomerId: string | null | undefined
  paymentStatus: string | null | undefined
  invoiceStatus: string | null | undefined
  additionalItem: string | null | undefined
}>

const HEADERS: Line = {
  section: 'Tab',
  teamIndividual: 'Team/Individual',
  invoicedExecutiveId: 'Invoiced Executive ID',
  invoicedExecutiveName: 'Invoiced Executive Name',
  executiveId: 'Executive ID',
  executiveName: 'Executive Name',
  assistantId: 'Assistant ID',
  assistantName: 'Assistant Name',
  successManagerId: 'Account Supervisor ID',
  successManagerName: 'Account Supervisor Name',
  pricingPackage: 'Pricing Package',
  startDate: 'Start Date',
  exception: 'Exception',
  hours: 'Hours',
  calculatedAmount: 'Calculated Amount',
  invoiceAmount: 'Invoice Amount',
  stripeCustomerId: 'Stripe Customer ID',
  paymentStatus: 'Payment Status',
  invoiceStatus: 'Invoice Status',
  additionalItem: 'Additional Item',
}

async function exportToCsv(
  reports: ReportSummary[],
  edits: Record<InvoicingReportFragment['id'], InvoiceEdit | null>,
  query: ReportsQuery,
) {
  const lines: Line[] = [HEADERS]

  for (const report of reports) {
    const edit = edits[report.id]
    if (edit?.isExcluded) {
      continue
    }

    const reportTotals = getReportTotals(report, edit)

    const reportCommon: Line = {
      section: SECTIONS.find(({ value }) => value === getReportSection(report))
        ?.label,
      teamIndividual: isTeam(report) ? 'Team' : 'Individual',
      invoicedExecutiveId: report.invoicedUser.id,
      invoicedExecutiveName: report.invoicedUser.profile.displayName,
      successManagerId: report.invoicedUser.csm?.id,
      successManagerName: report.invoicedUser.csm?.user.profile.displayName,
    }

    lines.push({
      ...reportCommon,
      calculatedAmount: reportTotals.calculatedAmount?.toFixed(2),
      invoiceAmount: reportTotals.invoicedAmount?.toFixed(2),
      hours: formatHours(reportTotals.hours, false),
      paymentStatus: report.invoicedUser.stripeCustomer?.paymentStatus,
      invoiceStatus: report.invoicedUser.stripeCustomer?.invoices?.[0]?.status,
      stripeCustomerId: report.invoicedUser.stripeCustomer?.id,
    })

    for (const p of report.packages) {
      if (edit?.excludedPackages?.[p.id]) {
        continue
      }

      const packageCommon: Line = {
        ...reportCommon,
        pricingPackage: p.pricing?.ref,
      }

      lines.push({
        ...packageCommon,
        startDate: p.principalUser.startDate,
        hours: formatHours(p.hours, false),
        calculatedAmount: p.amount?.toFixed(2),
      })

      for (const usage of p.togglUsages) {
        if (edit?.excludedUsages?.[usage.id]) {
          continue
        }

        lines.push({
          ...packageCommon,
          executiveId: usage.executive.id,
          executiveName: usage.executive.profile.displayName,
          assistantId: usage.assistant?.id,
          assistantName: usage.assistant?.profile.displayName,
          successManagerId: usage.executive.csm?.id,
          successManagerName: usage.executive.csm?.user.profile.displayName,
          exception: getUsageException(usage),
          hours: formatHours(usage.hours, false),
        })
      }
    }

    for (const item of edit?.additionalItems || []) {
      lines.push({
        ...reportCommon,
        calculatedAmount: item.amount?.toFixed(2),
        additionalItem: item.description,
      })
    }
  }

  const csv = lines
    .map((line) =>
      [
        line.section,
        line.teamIndividual,
        line.invoicedExecutiveId,
        line.invoicedExecutiveName,
        line.executiveId,
        line.executiveName,
        line.assistantId,
        line.assistantName,
        line.successManagerId,
        line.successManagerName,
        line.pricingPackage,
        line.startDate,
        line.exception,
        line.hours,
        line.calculatedAmount,
        line.invoiceAmount,
        line.stripeCustomerId,
        line.paymentStatus,
        line.invoiceStatus,
      ]
        .map((value) => (typeof value === 'string' ? value : ''))
        .join(','),
    )
    .join('\n')

  const stream = await createWritableCSVFileStream(
    `Double Invoicing ${DateTime.fromJSDate(query.from)
      .plus({ days: 10 })
      .toLocaleString({ year: 'numeric', month: 'long' })} (exported on ${
      new Date().toISOString().split('T')[0]
    }).csv`,
  )

  await stream.write(csv)
  await stream.close()
}

export default exportToCsv
