import styled from 'styled-components'

export const ActiveProposalsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
