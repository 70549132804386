import styled from 'styled-components'

export const Outer = styled.div`
  padding: 0 24px 48px;
  overflow: auto;
  height: 100%;
  width: 100%;
`

export const Inner = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 24px;
`
