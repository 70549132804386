import { DatePicker } from '@atlaskit/datetime-picker'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'

import { EntityPricingFragment, SelectEntityPricingInput } from '../../graphql'
import useNow from '../../lib/useNow'
import { Field } from '../form'

import ChangePricingPackage from './ChangePricingPackage'
import CurrentPricingPackage from './CurrentPricingPackage'
import SelectTeamPricing from './SelectTeamPricing'
import { SectionMessageOuter } from './styled'
import { EntityType } from './types'
import { isUser } from './utils'

export type CycleDates = {
  start?: Date
  end?: Date
}

type Props = {
  entity?: EntityType
  currentEntityPricing?: EntityPricingFragment | null
  nextEntityPricing?: EntityPricingFragment | null
  isExpiringPricing?: boolean
  startExpiringPricing?: () => void
  stopExpiringPricing?: () => void
  isSelectingPricing: boolean
  selectEntityPricing: (input: {
    variables: { input: SelectEntityPricingInput }
  }) => void
  startSelectingPricing: () => void
  stopSelectingPricing: () => void
  cancelScheduledPricing?: () => void
  expireUserPricing?: (input: {
    variables: { input: { userPricingId: string; expireAt: Date } }
  }) => void
  billingCycleDates?: {
    thisCycle: CycleDates
    nextCycle: CycleDates
  }
}

const PricingPackage = ({
  entity,
  currentEntityPricing,
  nextEntityPricing,
  isExpiringPricing,
  startExpiringPricing,
  stopExpiringPricing,
  isSelectingPricing,
  selectEntityPricing,
  startSelectingPricing,
  stopSelectingPricing,
  expireUserPricing,
  billingCycleDates,
  cancelScheduledPricing,
}: Props) => {
  const isUserEntity = isUser(entity)
  const [expiration, setExpiration] = useState<Date>()

  const doExpirePricing = useCallback(() => {
    if (
      entity?.id &&
      currentEntityPricing?.id &&
      expiration &&
      expireUserPricing
    ) {
      expireUserPricing({
        variables: {
          input: {
            userPricingId: currentEntityPricing.id,
            expireAt: expiration,
          },
        },
      })
    }
  }, [currentEntityPricing?.id, entity?.id, expiration, expireUserPricing])

  const now = useNow()
  const thisCycleDates = {
    start:
      billingCycleDates?.thisCycle.start || now.startOf('month').toJSDate(),
    end: billingCycleDates?.thisCycle.end || now.endOf('month').toJSDate(),
  }

  const nextCycleDates = {
    start:
      billingCycleDates?.nextCycle.start ||
      now.plus({ months: 1 }).startOf('month').toJSDate(),
    end:
      billingCycleDates?.nextCycle.end ||
      now.plus({ months: 1 }).endOf('month').toJSDate(),
  }

  if (!entity) {
    return null
  }
  return (
    <SectionMessageOuter>
      {isSelectingPricing ? (
        isUserEntity ? (
          <ChangePricingPackage
            selectEntityPricing={selectEntityPricing}
            stopSelectingPricing={stopSelectingPricing}
            entity={entity}
            currentPricingId={currentEntityPricing?.pricing?.id}
            thisCycleDates={thisCycleDates}
            nextCycleDates={nextCycleDates}
          />
        ) : (
          <SelectTeamPricing
            entity={entity}
            selectEntityPricing={selectEntityPricing}
            stopSelectingPricing={stopSelectingPricing}
          />
        )
      ) : isExpiringPricing ? (
        <SectionMessage
          appearance={'discovery'}
          title={'Set pricing package expiration'}
          actions={[
            <SectionMessageAction
              key={'cancel-expiring-pricing'}
              onClick={stopExpiringPricing}
            >
              {'Cancel'}
            </SectionMessageAction>,
            expiration ? (
              <SectionMessageAction onClick={doExpirePricing}>
                {'Expire'}
              </SectionMessageAction>
            ) : (
              <div />
            ),
          ]}
        >
          <Field label={'Expire on (11:59pm ET)'}>
            <DatePicker
              placeholder={'Select date (usually last day of month)'}
              value={
                expiration
                  ? DateTime.fromJSDate(new Date(expiration), {
                      zone: 'America/New_York',
                    }).toISODate()
                  : undefined
              }
              minDate={
                currentEntityPricing?.activeAt
                  ? DateTime.fromJSDate(
                      new Date(currentEntityPricing?.activeAt),
                      {
                        zone: 'America/New_York',
                      },
                    ).toISODate()
                  : undefined
              }
              onChange={(date) =>
                setExpiration(
                  date
                    ? DateTime.fromISO(date)
                        .setZone('America/New_York')
                        .endOf('day')
                        .toJSDate()
                    : undefined,
                )
              }
            />
          </Field>
        </SectionMessage>
      ) : (
        <CurrentPricingPackage
          startSelectingPricing={startSelectingPricing}
          startExpiringPricing={startExpiringPricing}
          currentEntityPricing={currentEntityPricing}
          nextEntityPricing={nextEntityPricing}
          nextBilingCycleStartDate={nextCycleDates.start}
          entity={entity}
          cancelScheduledPricing={cancelScheduledPricing}
        />
      )}
    </SectionMessageOuter>
  )
}

export default PricingPackage
