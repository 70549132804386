import Button from '@atlaskit/button'
import { useCallback, useState } from 'react'

import {
  TestExecFragment,
  useCreateTestExecLeadMutation,
} from '../../../../graphql'
import Header from '../Header'
import { Inner } from '../styled'

import { CreateLeadContainer } from './styled'

type Props = Pick<TestExecFragment, 'lead'> & {
  createTestExecLead: ReturnType<typeof useCreateTestExecLeadMutation>[0]
  createTestExecMatching: () => void
}

const LeadView = ({
  lead,
  createTestExecLead,
  createTestExecMatching,
}: Props) => {
  const [numberOfTeammates, setNumberOfTeammates] = useState(0)

  const onCreate = useCallback(() => {
    createTestExecLead({
      variables: {
        input: {
          numberOfTeammates,
        },
      },
    })
  }, [createTestExecLead, numberOfTeammates])

  return (
    <Inner>
      <Header testExec={{ lead }} />
      <div>
        {!lead?.id ? (
          <CreateLeadContainer>
            <Button appearance={'primary'} onClick={onCreate}>
              {'Create lead'}
            </Button>
            <p>{'with'}</p>
            <input
              type={'number'}
              value={numberOfTeammates}
              min={0}
              max={9}
              onChange={(e) => {
                setNumberOfTeammates(parseInt(e.target.value))
              }}
            />
            <p>{'teammates'}</p>
            {/* // TODO - add a isHighTouch checkbox here and hook into mutation */}
          </CreateLeadContainer>
        ) : (
          <Button
            appearance={'primary'}
            onClick={async () => {
              console.info('Create matching')
              await createTestExecMatching()
            }}
          >
            {'Create matching'}
          </Button>
        )}
      </div>
    </Inner>
  )
}

export default LeadView
