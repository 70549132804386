import {
  ProgressTracker as AkProgressTracker,
  Stages,
} from '@atlaskit/progress-tracker'
import { useMemo } from 'react'

import { TestExecFragment } from '../../../graphql'

enum LifecycleSteps {
  LEAD = 'Lead',
  MATCHING = 'Matching',
  ONBOARDING = 'Onboarding',
  ACTIVE = 'Active',
  PAUSED = 'Paused',
  CHURNED = 'Churned',
}
const STEPS = Object.values(LifecycleSteps)

type Props = {
  testExec?: TestExecFragment | null
}

const ProgressTracker = ({ testExec }: Props) => {
  const items = useMemo((): Stages => {
    const currentStageIndex = (() => {
      if (testExec?.user?.id) {
        return STEPS.indexOf(LifecycleSteps.ONBOARDING)
      }
      if (testExec?.matching?.id) {
        return STEPS.indexOf(LifecycleSteps.MATCHING)
      }

      return STEPS.indexOf(LifecycleSteps.LEAD)
    })()

    return STEPS.map((step, index) => ({
      id: step.toLowerCase(),
      label: step,
      percentageComplete: index < currentStageIndex ? 100 : 0,
      status:
        index < currentStageIndex
          ? 'visited'
          : index === currentStageIndex
          ? 'current'
          : 'unvisited',
    }))
  }, [testExec?.matching?.id, testExec?.user?.id])

  return <AkProgressTracker items={items} />
}

export default ProgressTracker
