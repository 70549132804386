import { DatePicker } from '@atlaskit/datetime-picker'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { SelectEntityPricingInput, TeamBillingFragment } from '../../graphql'
import useValues from '../../lib/useValues'
import PricingSelect from '../PricingSelect'
import { Field, TextArea } from '../form'

type Props = {
  entity: TeamBillingFragment
  selectEntityPricing: (input: {
    variables: { input: SelectEntityPricingInput }
  }) => void
  stopSelectingPricing: () => void
}

const SelectTeamPricing = ({
  entity,
  selectEntityPricing,
  stopSelectingPricing,
}: Props) => {
  const [newSelection, { setters }] = useValues<
    Omit<Partial<SelectEntityPricingInput>, 'entityId'>
  >({}, ['pricingId', 'notepad', 'activeAt', 'expireAt'])

  const didSelectValidPricing = newSelection.pricingId && newSelection.activeAt

  const savePricing = useCallback(async () => {
    const { pricingId, activeAt } = newSelection
    if (entity?.id && didSelectValidPricing && pricingId && activeAt) {
      await selectEntityPricing({
        variables: {
          input: {
            ...newSelection,
            entityId: entity.id,
            activeAt: new Date(activeAt),
            pricingId,
          },
        },
      })
      stopSelectingPricing()
    }
  }, [
    didSelectValidPricing,
    entity?.id,
    newSelection,
    selectEntityPricing,
    stopSelectingPricing,
  ])

  return (
    <SectionMessage
      appearance={'discovery'}
      title={'Select new pricing package'}
      actions={[
        <SectionMessageAction
          key={'cancel-select-pricing'}
          onClick={stopSelectingPricing}
        >
          {'Cancel'}
        </SectionMessageAction>,
        didSelectValidPricing ? (
          <SectionMessageAction onClick={savePricing}>
            {'Select'}
          </SectionMessageAction>
        ) : (
          <div />
        ),
      ]}
    >
      <PricingSelect
        valueId={newSelection.pricingId}
        onChangeValueId={setters.pricingId}
      />
      <Field label={'Active from (12:00am ET)'} isRequired>
        <DatePicker
          placeholder={'Select a date (usually first day of month)'}
          value={
            newSelection.activeAt
              ? DateTime.fromJSDate(new Date(newSelection.activeAt), {
                  zone: 'America/New_York',
                }).toISODate()
              : undefined
          }
          maxDate={
            newSelection.expireAt
              ? DateTime.fromJSDate(new Date(newSelection.expireAt), {
                  zone: 'America/New_York',
                }).toISODate()
              : undefined
          }
          onChange={(date) =>
            setters.activeAt?.(
              date
                ? DateTime.fromISO(date)
                    .setZone('America/New_York')
                    .startOf('day')
                    .toJSDate()
                : undefined,
            )
          }
        />
      </Field>
      <Field label={'Active until (11:59pm ET)'}>
        <DatePicker
          placeholder={'Optional (usually last day of month)'}
          value={
            newSelection.expireAt
              ? DateTime.fromJSDate(new Date(newSelection.expireAt), {
                  zone: 'America/New_York',
                }).toISODate()
              : undefined
          }
          minDate={
            newSelection.activeAt
              ? DateTime.fromJSDate(new Date(newSelection.activeAt), {
                  zone: 'America/New_York',
                }).toISODate()
              : undefined
          }
          onChange={(date) =>
            setters.expireAt?.(
              date
                ? DateTime.fromISO(date)
                    .setZone('America/New_York')
                    .endOf('day')
                    .toJSDate()
                : undefined,
            )
          }
        />
      </Field>
      <Field label={'Comments, description'}>
        <TextArea
          value={newSelection.notepad || undefined}
          onChangeValue={setters.notepad}
          placeholder={'Optional'}
        />
      </Field>
    </SectionMessage>
  )
}
export default SelectTeamPricing
