import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { DateTime } from 'luxon'
import { useCallback, useMemo } from 'react'

import { SelectEntityPricingInput } from '../../graphql'
import useSwitch from '../../lib/useSwitch'
import useValues from '../../lib/useValues'
import PricingSelect from '../PricingSelect'
import { Checkbox, Field, Select, TextArea } from '../form'

import { CycleDates } from './PricingPackage'
import useGetPlanChangeType, { PLAN_CHANGE_TYPE } from './getPlanChangeType'
import { EntityType } from './types'

type Props = {
  selectEntityPricing: (input: {
    variables: { input: SelectEntityPricingInput }
  }) => void
  entity?: EntityType
  currentPricingId?: string
  stopSelectingPricing: () => void
  thisCycleDates: CycleDates
  nextCycleDates: CycleDates
}

const cycleDateFormatter = (dates: CycleDates) =>
  `${dates.start?.toLocaleDateString()} - ${dates.end?.toLocaleDateString()}`

const dateSetToStartOfET = (date: Date) =>
  DateTime.fromJSDate(date, {
    zone: 'America/New_York',
  })
    .startOf('day')
    .toJSDate()

const ChangePricingPackage = ({
  entity,
  currentPricingId,
  stopSelectingPricing,
  selectEntityPricing,
  thisCycleDates,
  nextCycleDates,
}: Props) => {
  const [isOverridden, , , toggleOveride] = useSwitch(false)
  const [isThisCycle, setThisCycle, setNextCycle] = useSwitch(true)
  const [newSelection, { setters }] = useValues<
    Omit<
      Partial<SelectEntityPricingInput>,
      'activeAt' | 'expireAt' | 'entityId'
    >
  >({}, ['pricingId', 'notepad'])

  const newPricingId = newSelection.pricingId
  const planChangeType = useGetPlanChangeType(currentPricingId, newPricingId)
  const today = useMemo(() => new Date(), [])
  const showCheckbox = useMemo(() => {
    return (
      isThisCycle &&
      today.getDate() > 10 &&
      planChangeType === PLAN_CHANGE_TYPE.DOWNGRADE
    )
  }, [isThisCycle, planChangeType, today])
  const showSubmitPricingUpdateButton =
    newPricingId && ((showCheckbox && isOverridden) || !showCheckbox)

  const activeAtDate = useMemo(() => {
    const todaysDateTime = DateTime.fromJSDate(today)
    const tenthOfThisMonth = todaysDateTime.set({ day: 10 }).toJSDate()
    const nextCycleStart = nextCycleDates.start
      ? nextCycleDates.start
      : todaysDateTime.plus({ month: 1 }).startOf('month').toJSDate()
    // Base pricing is reflected based on the 10th day of the month
    if (
      planChangeType === PLAN_CHANGE_TYPE.DOWNGRADE &&
      isThisCycle &&
      today > tenthOfThisMonth
    ) {
      return isOverridden ? dateSetToStartOfET(tenthOfThisMonth) : null
    }
    return dateSetToStartOfET(isThisCycle ? today : nextCycleStart)
  }, [today, nextCycleDates.start, planChangeType, isThisCycle, isOverridden])

  const savePricing = useCallback(async () => {
    const { pricingId } = newSelection
    if (
      entity?.id &&
      showSubmitPricingUpdateButton &&
      pricingId &&
      activeAtDate
    ) {
      await selectEntityPricing({
        variables: {
          input: {
            ...newSelection,
            entityId: entity.id,
            activeAt: activeAtDate,
            pricingId,
          },
        },
      })
    }
    stopSelectingPricing()
  }, [
    newSelection,
    entity?.id,
    showSubmitPricingUpdateButton,
    activeAtDate,
    stopSelectingPricing,
    selectEntityPricing,
  ])

  const billingCycleOptions = useMemo(
    () => [
      {
        label: `This Billing Cycle (${cycleDateFormatter(thisCycleDates)})`,
        value: 'CURRENT',
      },
      {
        label: `Next Billing Cycle (${cycleDateFormatter(nextCycleDates)})`,
        value: 'NEXT',
      },
    ],
    [thisCycleDates, nextCycleDates],
  )

  const onBillingCycleChange = useCallback(
    (v) => {
      v.value === 'CURRENT' ? setThisCycle() : setNextCycle()
    },
    [setNextCycle, setThisCycle],
  )

  return (
    <SectionMessage
      appearance={'discovery'}
      title={'Update pricing package'}
      actions={[
        <SectionMessageAction
          key={'cancel-select-pricing'}
          onClick={stopSelectingPricing}
        >
          {'Cancel'}
        </SectionMessageAction>,
        showSubmitPricingUpdateButton ? (
          <SectionMessageAction onClick={savePricing}>
            {'Submit'}
          </SectionMessageAction>
        ) : (
          <div />
        ),
      ]}
    >
      <>
        <PricingSelect
          valueId={newSelection.pricingId}
          onChangeValueId={setters.pricingId}
          excludes={currentPricingId ? [currentPricingId] : []}
        />
        <Field label={'Billing Cycle'}>
          <Select
            options={billingCycleOptions}
            onChange={onBillingCycleChange}
            defaultValue={billingCycleOptions[0]}
          />
        </Field>
        {showCheckbox && (
          <Field>
            <Checkbox
              label={'Override billing downgrade policy'}
              isChecked={isOverridden}
              onChange={toggleOveride}
            />
          </Field>
        )}
        <Field label={'Comments, description (Optional)'}>
          <TextArea
            value={newSelection.notepad || undefined}
            onChangeValue={setters.notepad}
            placeholder={'Optional'}
          />
        </Field>
      </>
    </SectionMessage>
  )
}

export default ChangePricingPackage
