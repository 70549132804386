import Button from '@atlaskit/button'
import TestIcon from '@atlaskit/icon/glyph/jira/test-session'
import PageHeader from '@atlaskit/page-header'

import { LoadingSpinner } from '../../../components/Spinner'
import { Page } from '../../types'

import LeadView from './LeadView'
import MatchingView from './MatchingView'
import OnboardingView from './OnboardingView'
import ProgressTracker from './ProgressTracker'
import { Footer, Outer } from './styled'
import useTestExecLifecycle from './useTestExecLifecycle'

const TestExecLifecycle: Page = () => {
  const {
    testExec,
    refetchTestExec,
    createTestExecLead,
    createTestExecMatching,
    resetTestExecLifecycle,
    loading,
  } = useTestExecLifecycle()

  return (
    <Outer>
      <PageHeader>{'Test Exec Lifecycle'}</PageHeader>
      <ProgressTracker testExec={testExec} />
      {loading ? (
        <LoadingSpinner show />
      ) : (
        <>
          {testExec?.user ? (
            <OnboardingView
              lead={testExec.lead!}
              matching={testExec.matching!}
              user={testExec.user}
            />
          ) : testExec?.matching ? (
            <MatchingView
              lead={testExec.lead!}
              matching={testExec.matching}
              onExecCreated={refetchTestExec}
            />
          ) : (
            <LeadView
              lead={testExec?.lead}
              createTestExecLead={createTestExecLead}
              createTestExecMatching={createTestExecMatching}
            />
          )}

          {testExec?.lead && (
            <Footer>
              <Button
                appearance={'primary'}
                onClick={() => resetTestExecLifecycle()}
              >
                {/* // TODO - add tooltip or "Info note hover" button to explain that this cleans everything up */}
                {'Reset exec lifecycle'}
              </Button>
            </Footer>
          )}
        </>
      )}
    </Outer>
  )
}

TestExecLifecycle.path = '/admin/test-exec-lifecycle'
TestExecLifecycle.navTitle = 'Text Exec Lifecycle'
TestExecLifecycle.Icon = TestIcon
TestExecLifecycle.requiredOperationIds = ['Query.testExec']

export default TestExecLifecycle
