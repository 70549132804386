import { LoadingButton as Button } from '@atlaskit/button'
import React from 'react'

import {
  COMMUNITY_TEAM_ID,
  SlackLinkButton,
} from '../../../../components/SlackButton'
import VerticalTabs from '../../../../components/VerticalTabs'
import { Field } from '../../../../components/form'
import {
  useSwitchTransitionAssistantsMutation,
  useCompleteTransitionCallMutation,
  useArchiveTransitionMutation,
  useUnarchiveTransitionMutation,
  namedOperations,
  Matching_ForTransitionFullFragment,
} from '../../../../graphql'
import { Bundled } from '../utils'

import { InfoNotice, InfoNoticeText } from './styled'

type Props = {
  matching: Bundled<Matching_ForTransitionFullFragment>
}

const Changeover = ({ matching }: Props) => {
  const [switchTransitionAssistants, switchTransitionAssistantsMutation] =
    useSwitchTransitionAssistantsMutation({
      variables: {
        input: {
          matchingId: matching.id,
        },
      },
    })

  const [completeTransitionCall, completeTransitionCallMutation] =
    useCompleteTransitionCallMutation({
      variables: {
        input: {
          matchingId: matching.id,
        },
      },
    })

  const [archive, archiveMutation] = useArchiveTransitionMutation({
    variables: {
      input: {
        matchingId: matching.id,
      },
    },
    refetchQueries: [namedOperations.Query.ListMatchings],
  })

  const [unarchive, unarchiveMutation] = useUnarchiveTransitionMutation({
    variables: {
      input: {
        matchingId: matching.id,
      },
    },
    refetchQueries: [namedOperations.Query.ListMatchings],
  })

  return (
    <>
      <Field label={'Actions'}>
        <VerticalTabs
          tabs={[
            {
              label: 'Switch workspace assistants',
              isChecked: !!matching.transition!.didSwitchAssistantsAt,
              content: (
                <>
                  <Button
                    isDisabled={
                      !!matching.transition!.didSwitchAssistantsAt ||
                      !matching.confirmedAssistant
                    }
                    isLoading={switchTransitionAssistantsMutation.loading}
                    onClick={() => switchTransitionAssistants()}
                  >
                    {'Switch assistants'}
                  </Button>
                  <InfoNotice
                    type={'info'}
                    title={'When to do this?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {`This will simultaneously add ${
                        matching.confirmedAssistant?.profile.givenName || '🚧'
                      } to ${
                        matching.executive?.profile.givenName
                      }'s workspace, and remove ${
                        matching.transition!.fromAssistant?.profile.givenName
                      } from it. It should be triggered right before the transition call takes place.`}
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
            {
              label: 'Mark transition call as done',
              isChecked: matching.onboarding.isCompleted,
              content: (
                <>
                  <Button
                    isDisabled={matching.onboarding.isCompleted}
                    isLoading={completeTransitionCallMutation.loading}
                    onClick={() => completeTransitionCall()}
                  >
                    {'Mark transition call as done'}
                  </Button>
                  <InfoNotice
                    type={'info'}
                    title={'What will this update in Airtable?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {`This triggers changes to the Airtable client record:`}
                      <br />
                      <ul>
                        <li>
                          {"Add +1 to the exec's total number of transitions"}
                        </li>
                        <li>{'Update the last transition date to today'}</li>
                        <li>{'Move card "Status" to "Onboard"'}</li>
                        <li>
                          {'Unlink the old Double, and link the new Double'}
                        </li>
                      </ul>
                      <br />
                      {`Also triggers changes to the Airtable lead record:`}
                      <br />
                      <ul>
                        <li>{'Update "Next Step" to "Onboarded"'}</li>
                      </ul>
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
            {
              label: '🚧 1PW access switched',
              isChecked: !!matching.transition!.didSwitch1PasswordAt,
              content: (
                <>
                  <Button isDisabled>{'🚧 1PW access switched'}</Button>
                  <InfoNotice
                    type={'info'}
                    title={'Why do I have to do this?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {
                        '1PW access needs to be manually removed by the CS team. Once done, triggering this step will send an email to the old Double, letting them know their 1PW access has been removed.'
                      }
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
            {
              label: '🚧 Email feedback request to exec',
              isChecked: !!matching.transition!.didRequestExecutiveFeedbackAt,
              content: (
                <>
                  <Button isDisabled>
                    {'🚧 Email feedback request to exec'}
                  </Button>
                  <InfoNotice
                    type={'info'}
                    title={'What does this do?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {
                        'This sends the executive a Templatized email, asking for feedback on their transition and experience with the new Double.'
                      }
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
            {
              label: 'Follow-up request to new Double',
              isChecked: !!matching.transition!.didFollowUpWithNewAssistantAt,
              content: (
                <>
                  <SlackLinkButton
                    teamId={COMMUNITY_TEAM_ID}
                    userId={matching.confirmedAssistant?.slackUserId}
                    appearance={'default'}
                  >
                    {'Open Slack DM'}
                  </SlackLinkButton>
                  <Button
                    isDisabled={
                      !!matching.transition!.didFollowUpWithNewAssistantAt
                    }
                  >
                    {'Mark follow-up as done'}
                  </Button>
                  <InfoNotice
                    type={'info'}
                    title={'When should we do this?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {
                        "This should be done by the NEW Double's Account Supervisor. Use the button above to open a Slack DM with the Double and make sure that things are going well with the new collaboration. Once done, please mark is as completed here."
                      }
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
            {
              label: 'Archive transition',
              isChecked: !!matching.completedAt,
              content: matching.completedAt ? (
                <Button
                  isLoading={unarchiveMutation.loading}
                  onClick={() => unarchive()}
                >
                  {'Un-archive transition'}
                </Button>
              ) : (
                <>
                  <Button
                    isLoading={archiveMutation.loading}
                    onClick={() => archive()}
                  >
                    {'Archive transition'}
                  </Button>
                  <InfoNotice
                    type={'info'}
                    title={'When to do this?'}
                    noTopUnset
                  >
                    <InfoNoticeText>
                      {`This will mark the transition request as completed in Airtable and move this transition to the "Archived" list in Cockpit. Do this when everyone is happy and feels accomplished and you never want to here about this transition again. Ever. Again.`}
                    </InfoNoticeText>
                  </InfoNotice>
                </>
              ),
            },
          ]}
        />
      </Field>
    </>
  )
}

export default Changeover
