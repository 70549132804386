import React from 'react'
import styled, { css } from 'styled-components'

import { BasicTeamFragment } from '../../../graphql'
import TeamCell from '../Cell'

const OptionContainer = styled.div<{ isFocused: boolean }>`
  padding: 4px 0 4px 6px;
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: #e8e9ee;
    `}
`

export const MultiValueLabel = ({
  innerProps,
  data: team,
  innerRef,
}: {
  innerProps: any
  data: BasicTeamFragment
  innerRef: any
}) => {
  return <TeamCell ref={innerRef} team={team} size={'xsmall'} {...innerProps} />
}

export const Option = ({
  innerRef,
  data: team,
  innerProps,
  isFocused,
}: {
  innerRef: any
  data: BasicTeamFragment
  innerProps: any
  isFocused: boolean
}) => {
  return (
    <OptionContainer ref={innerRef} {...innerProps} isFocused={isFocused}>
      <TeamCell team={team} size={'small'} />
    </OptionContainer>
  )
}

export const SingleValue = ({
  innerProps,
  data: team,
}: {
  innerProps: any
  data: BasicTeamFragment
}) => {
  return <TeamCell team={team} size={'small'} {...innerProps} />
}
