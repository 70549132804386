import { DropdownItem } from '@atlaskit/dropdown-menu'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import { useCallback } from 'react'

import { TestExecFragment } from '../../../../graphql'
import { NonNullableRequired } from '../../../../types'
import Header from '../Header'
import { BodyContainer, Inner } from '../styled'

import ConfirmedView from './ConfirmedView'
import ProposalsView from './ProposalsView'

type Props = NonNullableRequired<
  Pick<TestExecFragment, 'lead' | 'matching'>
> & {
  onExecCreated: () => void
}

const MatchingView = ({ lead, matching, onExecCreated }: Props) => {
  const copyMatchingIdToClipboard = useCallback(() => {
    navigator.clipboard.writeText(matching.id)
  }, [matching.id])

  return (
    <Inner>
      <Header
        testExec={{ lead, matching }}
        actionItems={
          <DropdownItem
            key={'copy-matching-id'}
            onClick={copyMatchingIdToClipboard}
            elemBefore={<CopyIcon size={'small'} label={''} />}
            description={matching.id}
          >
            {'Copy Matching Id'}
          </DropdownItem>
        }
      />
      <BodyContainer>
        {!matching.confirmedAssistant?.id ? (
          <ProposalsView matching={matching} />
        ) : (
          <ConfirmedView matching={matching} onExecCreated={onExecCreated} />
        )}
      </BodyContainer>
    </Inner>
  )
}

export default MatchingView
