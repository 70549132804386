import React, { useCallback, useMemo, ComponentProps } from 'react'
import { useHistory } from 'react-router'

import { CreateSandboxInput, useCreateSandboxMutation } from '../../graphql'
import useValues from '../../lib/useValues'
import Modal from '../Modal'
import { Field, TextField, FieldsRow, Checkbox } from '../form'
import TeamSelect from '../teams/Select'

type Props = ComponentProps<typeof Modal>

const CreateModal = ({ ...modalProps }: Props) => {
  const history = useHistory()

  const [formValues, { reset: resetForm, setters }] =
    useValues<CreateSandboxInput>(
      {
        givenName: '',
        familyName: '',
        email: '',
        createExecutiveWorkspace: false,
        teamId: undefined,
      },
      [
        'givenName',
        'familyName',
        'email',
        'createExecutiveWorkspace',
        'teamId',
      ],
    )

  const reset = useCallback(() => {
    resetForm()
  }, [resetForm])

  const [createSandbox, { loading: isLoading }] = useCreateSandboxMutation({
    onCompleted({ user: { id } }) {
      // @ts-ignore
      modalProps.onClose?.()
      history.push(`/users/${id}/infos`)
    },
  })

  const onSubmit = useCallback(() => {
    createSandbox({
      variables: { input: formValues },
    })
  }, [createSandbox, formValues])

  const isValidForm = useMemo(() => {
    if (
      !(
        formValues.givenName?.trim() &&
        formValues.familyName?.trim() &&
        formValues.email?.trim() &&
        (!formValues.createExecutiveWorkspace || formValues.teamId)
      )
    ) {
      return false
    }

    return true
  }, [
    formValues.createExecutiveWorkspace,
    formValues.email,
    formValues.familyName,
    formValues.givenName,
    formValues.teamId,
  ])

  return (
    <Modal
      onCloseComplete={reset}
      width={'small'}
      heading={'Create sandbox user for tests 🤖'}
      autoFocus
      actions={[
        {
          text: 'Create',
          onClick: onSubmit,
          isDisabled: !isValidForm,
          isLoading,
        },
        { text: 'Cancel', onClick: modalProps.onClose },
      ]}
      {...modalProps}
    >
      <Field label={'Name'} isRequired>
        <FieldsRow>
          <TextField
            placeholder={'Given name'}
            value={formValues.givenName}
            onChangeValue={setters.givenName}
            isInvalid={!formValues.givenName?.trim()}
          />
          <TextField
            placeholder={'Family name'}
            value={formValues.familyName || ''}
            onChangeValue={setters.familyName}
            isInvalid={!formValues.familyName?.trim()}
          />
        </FieldsRow>
      </Field>

      <Field label={'Email'} isRequired>
        <TextField
          type={'email'}
          placeholder={'james.doe@gmail.com'}
          value={formValues.email}
          onChangeValue={setters.email}
          isInvalid={!formValues.email?.trim()}
        />
      </Field>

      <Field label={'Actions'}>
        <Checkbox
          isChecked={!!formValues.createExecutiveWorkspace}
          onChangeValue={(value) => {
            setters.createExecutiveWorkspace?.(value)

            if (!value) {
              // reset team selection
              setters.teamId?.(undefined)
            }
          }}
          label={'Create a exec workspace for this test user'}
        />
      </Field>
      {formValues.createExecutiveWorkspace && (
        <>
          <Field label={'Team'} isRequired>
            <TeamSelect
              value={formValues.teamId ? { id: formValues.teamId } : undefined}
              isClearable
              onChange={(team) => setters.teamId?.(team?.id)}
              placeholder={'Assign team'}
              maxMenuHeight={130}
              validationState={!formValues.teamId ? 'error' : undefined}
            />
          </Field>
          <div style={{ height: 140 }} />
        </>
      )}
    </Modal>
  )
}

export default CreateModal
