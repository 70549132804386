// @ts-ignore
import { Item } from '@atlaskit/navigation-next'
import Tooltip from '@atlaskit/tooltip'
import { useCallback } from 'react'

import { TEAM_READY } from '../../../../FEATURE_FLAGS'
import TeamAvatar from '../../../../components/teams/Avatar'
import { UserAvatarsGroup } from '../../../../components/users/Avatar'
import { type WorkspaceWithTeamFragment } from '../../../../graphql'
import useSwitch from '../../../../lib/useSwitch'
import { NavLink } from '../../NavigationSidebar/styled'
import { getFriendlyTeamName } from '../../User/utils'

import UpdateWorkspaceTeamModal from './UpdateWorkspaceTeamModal'

type Props = {
  userId: string
  isSelected: boolean
  workspace: WorkspaceWithTeamFragment
}

const preventPropagation = (e: React.MouseEvent) => {
  e.stopPropagation()
  e.preventDefault()
}

const WorkspaceItem = ({ workspace, isSelected, userId }: Props) => {
  const team = workspace.team

  const friendlyTeamName = getFriendlyTeamName(team?.name)

  const [
    isUpdateWorkspaceTeamModalOpen,
    openUpdateWorkspaceTeamModal,
    closeUpdateWorkspaceTeamModal,
  ] = useSwitch(false)

  const onClickTeamAvatar = useCallback(
    (e) => {
      preventPropagation(e)

      openUpdateWorkspaceTeamModal()
    },
    [openUpdateWorkspaceTeamModal],
  )

  return (
    <NavLink
      key={workspace.id}
      to={`/users/${userId}/workspaces/${workspace.id}`}
      $striked={workspace.isArchived}
    >
      {isUpdateWorkspaceTeamModalOpen && (
        <div onClick={preventPropagation}>
          <UpdateWorkspaceTeamModal
            workspace={workspace}
            onClose={closeUpdateWorkspaceTeamModal}
          />
        </div>
      )}

      <Item
        isSelected={isSelected}
        text={workspace.executives[0]?.profile.displayName}
        subText={workspace.assistants[0]?.profile.displayName}
        before={() => (
          <UserAvatarsGroup
            size={'small'}
            maxCount={2}
            users={[...workspace.executives, ...workspace.assistants]}
            isPool={workspace.isPool}
          />
        )}
        after={() =>
          team ? (
            <Tooltip content={friendlyTeamName}>
              <TeamAvatar
                onClick={TEAM_READY ? onClickTeamAvatar : undefined}
                team={team}
                size={'xsmall'}
              />
            </Tooltip>
          ) : null
        }
        spacing={'compact'}
      />
    </NavLink>
  )
}

export default WorkspaceItem
