import React, { ComponentProps, useCallback } from 'react'

import { BasicUserFragment, useUpdateUserMutation } from '../../graphql'

import UserSelectorModal from './SelectorModal'

type Props = Omit<ComponentProps<typeof UserSelectorModal>, 'onSelectUser'> & {
  userId: string
}

const AssignAssistantSuccessManagerModal = ({
  userId: entityId,
  onClose,
  ...modalProps
}: Props) => {
  const [updateUser] = useUpdateUserMutation()

  const assignSuccessManager = useCallback(
    async (manager: BasicUserFragment) => {
      await updateUser({
        variables: {
          userId: entityId,
          input: {
            successManagerId: manager.id,
          },
        },
      })

      onClose?.()
    },
    [entityId, onClose, updateUser],
  )

  return (
    <UserSelectorModal
      adminRoles={['EA_TEAM_LEAD']}
      {...modalProps}
      onSelectUser={assignSuccessManager}
      onClose={onClose}
    />
  )
}

export default AssignAssistantSuccessManagerModal
