import { useMemo } from 'react'

import { TestExecFragment } from '../../../../../graphql'

import { Container, Subtitle } from './styled'

type Props = {
  testExec: TestExecFragment
}

const ExecNameTitle = ({ testExec: { lead, user } }: Props) => {
  const name = user?.profile.displayName || lead?.name

  const numberOfTeammateLeadCards = useMemo<number>(
    () => (lead?.fields?.['Teammates (for bundling)'] || []).length,
    [lead?.fields],
  )

  return (
    <Container>
      {!lead?.id ? <div>{'No lead card found'}</div> : <h2>{name}</h2>}
      {numberOfTeammateLeadCards ? (
        <Subtitle>{` (+${numberOfTeammateLeadCards} teammates)`}</Subtitle>
      ) : null}
    </Container>
  )
}

export default ExecNameTitle
