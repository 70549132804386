import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import { useMemo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  CancelScheduledEntityPricingUpdateInput,
  EntityPricingFragment,
  ExecutivableBillingFragment,
} from '../../graphql'
import { formatDate } from '../utils'

import { EntityType } from './types'
import { isUser } from './utils'

type Props = {
  currentEntityPricing?: EntityPricingFragment | null
  nextEntityPricing?: EntityPricingFragment | null
  nextBilingCycleStartDate?: Date
  entity?: EntityType
  startSelectingPricing: () => void
  startExpiringPricing?: () => void
  cancelScheduledPricing?: (input: {
    variables: { input: CancelScheduledEntityPricingUpdateInput }
  }) => void
}

const isJsxElement = (
  item: JSX.Element | false | undefined | null,
): item is JSX.Element => !!item

const CurrentPricingPackage = ({
  currentEntityPricing,
  nextEntityPricing,
  nextBilingCycleStartDate,
  entity,
  startExpiringPricing,
  startSelectingPricing,
  cancelScheduledPricing,
}: Props) => {
  const navHistory = useHistory()
  const { pathname } = useLocation()
  const isUserEntity = isUser(entity)

  const onCancelClick = useCallback(async () => {
    if (!nextEntityPricing || !cancelScheduledPricing) {
      return
    }
    await cancelScheduledPricing({
      variables: {
        input: {
          userId: nextEntityPricing.owner.id,
          archivingUserPricingId: nextEntityPricing.id,
        },
      },
    })
  }, [nextEntityPricing, cancelScheduledPricing])

  const currentPricing = currentEntityPricing?.pricing
  const nextPricing = nextEntityPricing?.pricing

  const isNotOwnUserPricing =
    isUserEntity && currentEntityPricing?.owner?.id !== entity.id
  const canRemovePricing = Boolean(
    startExpiringPricing &&
      !isNotOwnUserPricing &&
      isUserEntity &&
      (entity as ExecutivableBillingFragment).principalUser?.id,
  )

  const hasPlanChange = useMemo(
    () => currentPricing && nextPricing && currentPricing.id !== nextPricing.id,
    [currentPricing, nextPricing],
  )

  const planChangeText = useMemo(() => {
    return hasPlanChange
      ? currentPricing?.modelConfig.basePrice >
        nextPricing?.modelConfig.basePrice
        ? 'downgrade'
        : 'upgrade'
      : ''
  }, [currentPricing, nextPricing, hasPlanChange])

  return currentPricing ? (
    <SectionMessage
      appearance={'information'}
      title={
        hasPlanChange
          ? `Plan ${planChangeText} requested`
          : 'Current pricing package'
      }
      actions={[
        <SectionMessageAction
          key={'view-pricing'}
          onClick={() =>
            navHistory.push(`${pathname}/pricings/${currentPricing.id}`)
          }
        >
          {'View pricing'}
        </SectionMessageAction>,
        isUserEntity && hasPlanChange ? (
          <SectionMessageAction key={'cancel-pricing'} onClick={onCancelClick}>
            {'Cancel request'}
          </SectionMessageAction>
        ) : (
          <SectionMessageAction
            key={'change-pricing'}
            onClick={startSelectingPricing}
          >
            {'Change package'}
          </SectionMessageAction>
        ),
        canRemovePricing && (
          <SectionMessageAction onClick={startExpiringPricing}>
            {'Remove package'}
          </SectionMessageAction>
        ),
      ].filter(isJsxElement)}
    >
      <p>
        {hasPlanChange ? (
          <>
            {'The current plan, '}
            <strong>{`${currentPricing.ref} (${currentPricing.priceDescription})`}</strong>
            {`, will be ${planChangeText}d to `}{' '}
            <strong>{`${nextPricing?.ref} (${nextPricing?.priceDescription})`}</strong>{' '}
            {`starting with the next billing cycle`}{' '}
            {nextBilingCycleStartDate ? (
              <>
                {'on '}
                <strong>{formatDate(nextBilingCycleStartDate)}</strong>
              </>
            ) : (
              ''
            )}
            {`.`}
          </>
        ) : (
          <>
            {`The ${isUserEntity ? `User's` : `Teams's`} current plan is the `}
            <strong>
              {/* TS gymnastics because we know entity is a user if isNotOwnUserPricing is true */}
              {isNotOwnUserPricing && 'profile' in currentEntityPricing?.owner
                ? `${currentEntityPricing.owner.profile.displayName}’s `
                : ''}
              {currentPricing.ref}
            </strong>
            {' package'}
            {currentPricing.priceDescription
              ? ` for ${currentPricing.priceDescription}. `
              : ''}
            <br />
            {currentPricing.hoursDescription}
          </>
        )}

        {isNotOwnUserPricing && (
          <>
            <br />
            <br />
            {'Hours Limit: '}
            <strong>{entity.hoursLimit || 'no limit'}</strong>
            <br />
            {'Are personal tasks allowed? '}
            <strong>
              {entity.isAllowedPersonal == null
                ? 'TBD'
                : entity.isAllowedPersonal
                ? 'Yes'
                : 'No'}
            </strong>
          </>
        )}
      </p>
    </SectionMessage>
  ) : (
    <SectionMessage
      appearance={'warning'}
      title={'No package selected'}
      actions={[
        <SectionMessageAction
          key={'select-pricing'}
          onClick={() => startSelectingPricing()}
        >
          {'Select a pricing package'}
        </SectionMessageAction>,
      ]}
    >
      <p>{`This ${
        isUserEntity ? 'user' : 'team'
      } has no pricing package assigned yet`}</p>
    </SectionMessage>
  )
}

export default CurrentPricingPackage
