import { DateTimePicker } from '@atlaskit/datetime-picker'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import React, { ComponentProps, useMemo, useCallback, useEffect } from 'react'

import Modal from '../../../../components/Modal'
import TzInfoNotice from '../../../../components/TzInfoNotice'
import { Field, Checkbox, FieldsRow } from '../../../../components/form'
import WorkspaceOnboardingAvailabilityButton from '../../../../components/onboarding-availability/WorkspaceOnboardingAvailabilityButton'
import { SingleUserSelect } from '../../../../components/users/Select'
import {
  useScheduleOnboardingCallMutation,
  ScheduleOnboardingCallMutationVariables,
  useGetWorkspaceOnboardingQuery,
  UserCategory,
} from '../../../../graphql'
import useValues from '../../../../lib/useValues'

const DATE_PICKER_TIMES: `${number}:${number}`[] = []
for (let hour = 5; hour < 22; hour++) {
  DATE_PICKER_TIMES.push(
    `${hour}:00`,
    `${hour}:10`,
    `${hour}:20`,
    `${hour}:30`,
    `${hour}:40`,
    `${hour}:50`,
  )
}

type Props = ComponentProps<typeof Modal> & {
  workspaceId: string
}

const ScheduleOnboardingCallModal = ({ workspaceId, ...modalProps }: Props) => {
  const [values, { reset, setters }] = useValues<
    Omit<ScheduleOnboardingCallMutationVariables['input'], 'workspaceId'>
  >(
    {
      specialistId: null,
      validateAvailability: true,
      at: '',
    },
    ['specialistId', 'validateAvailability', 'at'],
  )

  const { data: workspaceOnboarding } = useGetWorkspaceOnboardingQuery({
    variables: {
      workspaceId,
    },
  })

  // pre-populate specialist ID
  useEffect(() => {
    if (
      workspaceOnboarding?.workspace.onboarding?.specialist?.id &&
      !values.specialistId
    ) {
      setters.specialistId?.(
        workspaceOnboarding?.workspace.onboarding?.specialist?.id,
      )
    }
  }, [workspaceOnboarding, values.specialistId, setters])

  const eventAt = values.at ? new Date(values.at) : undefined

  const [_scheduleOnboardingCall, { loading: isLoading }] =
    useScheduleOnboardingCallMutation()

  const scheduleOnboardingCall = useCallback(async () => {
    if (values.at) {
      await _scheduleOnboardingCall({
        variables: {
          input: {
            ...values,
            workspaceId,
          },
        },
      })
      modalProps.onClose?.()
    }
  }, [values, _scheduleOnboardingCall, modalProps, workspaceId])

  const actions = useMemo(
    () => [
      {
        text: 'Schedule',
        onClick: scheduleOnboardingCall,
        isDisabled: isLoading || !values.at,
        isLoading,
      },
      { text: 'Cancel', onClick: modalProps.onClose },
    ],
    [modalProps.onClose, scheduleOnboardingCall, isLoading, values.at],
  )

  return (
    <Modal
      heading={'Schedule onboarding call'}
      width={'small'}
      onCloseComplete={reset}
      {...modalProps}
      actions={actions}
      autoFocus={false}
    >
      <Field
        label={'Onboarding Specialist'}
        helperMessage={
          'Leave blank for round-robin, or select to force an Onboarding Specialist'
        }
      >
        <SingleUserSelect
          value={values.specialistId ? { id: values.specialistId } : undefined}
          categories={[UserCategory.TEAM]}
          adminRoles={['ONBOARDING_SPECIALIST']}
          isClearable={false}
          onChange={(user: null | { id: string }) =>
            setters?.specialistId?.(user?.id || null)
          }
          menuPlacement={'bottom'}
          maxMenuHeight={130}
          defaultOptions
        />
      </Field>

      <Field
        label={'Call date & time'}
        isRequired
        helperMessage={'In YOUR LOCAL time-zone'}
      >
        <FieldsRow>
          <DateTimePicker
            value={eventAt?.toISOString()}
            onChange={(dateTimeStr) =>
              setters.at(dateTimeStr ? new Date(dateTimeStr) : null)
            }
            autoFocus={false}
            datePickerProps={{
              placeholder: '2018-04-01',
              minDate: new Date().toISOString().split('T')[0],
              disabledDateFilter: (date) =>
                new Date(`${date}T00:00:00Z`).getUTCDay() % 6 === 0,
            }}
            times={DATE_PICKER_TIMES}
            spacing={'compact'}
          />
          <WorkspaceOnboardingAvailabilityButton
            workspaceId={workspaceId}
            iconBefore={<CalendarIcon label={''} />}
            appearance={'subtle'}
          />
        </FieldsRow>
      </Field>

      <Field
        label={''}
        helperMessage={
          'If un-checked then it might create events when assistant or Onboarding Specialist is not available. Bypass validation at your own risk.'
        }
      >
        <Checkbox
          label={'Validate slot availability'}
          isChecked={values.validateAvailability}
          onChangeValue={setters.validateAvailability}
        />
      </Field>

      {!!eventAt && (
        <TzInfoNotice
          dateTime={eventAt}
          title={'Verify date-time in all time-zones'}
        />
      )}
    </Modal>
  )
}

export default ScheduleOnboardingCallModal
