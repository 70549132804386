import { SpinnerSize } from '@blueprintjs/core'
import React from 'react'

import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'

import { ModalOuter, SectionTitle } from './styled'

type Props = {
  doneCount: number
  totalCount: number
}

const BatchModal = ({ doneCount, totalCount }: Props) => {
  return (
    <Modal isOpen width={'small'} heading={'Batching Invoicing'} autoFocus>
      <ModalOuter>
        <Spinner size={SpinnerSize.LARGE} />

        <SectionTitle>{`${doneCount} of ${totalCount} completed`}</SectionTitle>
      </ModalOuter>
    </Modal>
  )
}

export default BatchModal
