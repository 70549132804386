import {
  useCreateTestExecLeadMutation,
  useCreateTestExecMatchingMutation,
  useGetTestExecQuery,
  useResetTestExecLifecycleMutation,
} from '../../../graphql'

const useTestExecLifecycle = () => {
  const {
    data,
    loading: getTestExecLoading,
    refetch: refetchTestExec,
  } = useGetTestExecQuery({
    // triggers `loading` update on refetch
    notifyOnNetworkStatusChange: true,
  })

  const [createTestExecLead, { loading: createTestExecLeadLoading }] =
    useCreateTestExecLeadMutation({
      // refetch test exec data on successful test exec lead creation
      onCompleted: (data) => {
        if (data.createTestExecLead.isSuccess) {
          refetchTestExec()
        }
      },
    })
  const [createTestExecMatching, { loading: createTestExecMatchingLoading }] =
    useCreateTestExecMatchingMutation({
      // refetch test exec data on successful test exec matching creation
      onCompleted: (data) => {
        if (data.createTestExecMatching.isSuccess) {
          refetchTestExec()
        }
      },
    })
  const [resetTestExecLifecycle, { loading: resetTestExecLifecycleLoading }] =
    useResetTestExecLifecycleMutation({
      // refetch test exec data on successful test exec lifecycle reset
      onCompleted: (data) => {
        if (data.resetTestExecLifecycle.isSuccess) {
          refetchTestExec()
        }
      },
    })

  return {
    testExec: data?.testExec,
    refetchTestExec,
    createTestExecLead,
    createTestExecMatching,
    resetTestExecLifecycle,
    loading:
      createTestExecLeadLoading ||
      createTestExecMatchingLoading ||
      resetTestExecLifecycleLoading ||
      getTestExecLoading,
  }
}

export default useTestExecLifecycle
